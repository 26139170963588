import React, { useEffect, useState } from "react";
import { Col, Form, Input, message, Row } from "antd";

import Modal from "src/components/common/Modal";
import { LocationForm, LocationProps } from "src/interface/admin/location";
import location from "src/services/user/locations";

import TextArea from "antd/lib/input/TextArea";

const CreateLocation = ({
	toggleModal,
	modal,
	callBack,
	selected,
}: LocationProps.Create) => {
	const [form] = Form.useForm();

	useEffect(() => {
		if (selected) {
			form.setFieldsValue({
				name: selected.name,
				description: selected.description,
				latitude: selected.latitude,
				longitude: selected.longitude,
			});
		}
	}, [selected]);

	const handleSubmit = async (values: LocationForm.Create) => {
		if (selected) {
			const res = await location.Update(selected.id, values);
			if (res?.status && res?.data) {
				message.success("Location updated successfully!");
				reset();
			}
		} else {
			const res = await location.Create(values);
			if (res?.status && res?.data) {
				message.success("Location created successfully!");
				reset();
			}
		}
	};

	const reset = () => {
		form.resetFields();
		toggleModal();
		callBack();
	};

	return (
		<>
			<Modal
				title={!selected ? "Create Location" : "Update Location"}
				onCancel={reset}
				open={modal}
				footer={
					<div className="space-x-3 flex justify-end">
						<button className="btn btn--border py-1 px-6" onClick={reset}>
							Cancel
						</button>
						<button
							className="btn btn--primary py-1 px-6"
							form="create_location_form"
							type="submit"
						>
							{selected ? "Update" : "Create"}
						</button>
					</div>
				}
			>
				<Form
					className="space-y-4"
					form={form}
					layout="vertical"
					name="create_location_form"
					initialValues={{
						name: "",
						blocked: false,
					}}
					onFinish={handleSubmit}
				>
					<Form.Item
						label="Name"
						name="name"
						rules={[{ required: true, message: "Missing location name" }]}
					>
						<Input className="input--primary p-2 border-0 text-white"></Input>
					</Form.Item>
					<Form.Item label="Description" name="description">
						<TextArea className="input--primary p-2 border-0 text-white" />
					</Form.Item>
					<Row gutter={[12, 0]}>
						<Col span={12}>
							<Form.Item
								label="Latitude"
								name="latitude"
								rules={[{ required: true, message: "Required" }]}
							>
								<Input
									className="input--primary p-2 border-0 text-white number"
									type="number"
								/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item
								label="Longitude"
								name="longitude"
								rules={[{ required: true, message: "Required" }]}
							>
								<Input
									className="input--primary p-2 border-0 text-white number"
									type="number"
								/>
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</Modal>
		</>
	);
};

export default CreateLocation;
