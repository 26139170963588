import { Resp } from "src/interface/common";
import {
	Device,
	Map,
	RosServiceCallRequestOptions,
	Telemetry,
} from "src/interface/device";
import { base64ToArrayBuffer } from "src/utils/utils";
import request from "../../utils/request";
import lz4 from "lz4js";
import Eventsource from "eventsource";

const { REACT_APP_RCC_HOST: RCC_HOST } = process.env;

const list = (): Promise<Resp<Device[]>> => {
	console.log("In Devices Service", localStorage.getItem("access_token"));
	return request(`${RCC_HOST}/api/v1/devices`, {
		method: "GET",
		headers: {
			Organization: process.env.REACT_APP_RCC_ORGANIZATION || "",
			"x-api-key": process.env.REACT_APP_RCC_API_KEY || "",
		},
		params: {
			type: "ROOM_SERVICE",
		},
	});
};

const Detail = (id: string): Promise<Resp<Device>> => {
	return request(`${RCC_HOST}/api/v1/devices/${id}`, {
		method: "GET",
		headers: {
			Organization: process.env.REACT_APP_RCC_ORGANIZATION || "",
			"x-api-key": process.env.REACT_APP_RCC_API_KEY || "",
		},
	});
};

type TelemetryParams = {
	start: string;
	stop: string;
	measurement: string;
	field: string;
	window: string;
};
function GetTelemetry<T>(
	id: string,
	params: TelemetryParams
): Promise<Resp<Telemetry<T>>> {
	return request(`${RCC_HOST}/api/v1/devices/${id}/ros/telemetry`, {
		method: "GET",
		headers: {
			Organization: process.env.REACT_APP_RCC_ORGANIZATION || "",
			"x-api-key": process.env.REACT_APP_RCC_API_KEY || "",
		},
		params,
	});
}

function GetLatestTelemetry<T>(id: string): Promise<Resp<any>> {
	return request(`${RCC_HOST}/api/v1/devices/${id}/ros/telemetry?type=recent`, {
		method: "GET",
		headers: {
			Organization: process.env.REACT_APP_RCC_ORGANIZATION || "",
			"x-api-key": process.env.REACT_APP_RCC_API_KEY || "",
		},
	});
}

function GetLiveTelemetry<T>(id: string) {
	const event = new Eventsource(
		`${RCC_HOST}/api/v1/devices/${id}/ros/telemetry?type=live`,
		{
			headers: {
				Organization: process.env.REACT_APP_RCC_ORGANIZATION || "",
				"x-api-key": process.env.REACT_APP_RCC_API_KEY || "",
			},
		}
	);

	return event;
}

const GetMap = async (id: string) => {
	return await rosServiceCall<{ map: Map }>(id, {
		command: "/static_map",
		compressed: true,
		args: {},
	});
};

function rosServiceCall<T>(
	id: string,
	options: RosServiceCallRequestOptions
): Promise<T> {
	return new Promise(async (resolve) => {
		const res = await request(`${RCC_HOST}/api/v1/devices/${id}/ros/service`, {
			headers: {
				Organization: process.env.REACT_APP_RCC_ORGANIZATION || "",
				"x-api-key": process.env.REACT_APP_RCC_API_KEY || "",
			},
			method: "POST",
			data: {
				command: options.command,
				compressed: options.compressed,
				data: {
					args: options.args,
				},
			},
		});
		if (res.status) {
			if (options.compressed) {
				const decoded = base64ToArrayBuffer(res.data);
				const buffer = Buffer.from(decoded);
				const decompressed = lz4.decompress(buffer);
				const decodedBuffer = Buffer.from(decompressed);
				return resolve(JSON.parse(decodedBuffer.toString()));
			} else {
				return resolve(JSON.parse(res.data));
			}
		}
	});
}

export default {
	list,
	Detail,
	GetMap,
	GetTelemetry,
	GetLiveTelemetry,
	GetLatestTelemetry,
};
