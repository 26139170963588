import { FC, useEffect, useState } from "react";
import * as createjs from "createjs-module";
import { Map, Pose, LaserScanForMap } from "src/interface/device";
import ImageViewer from "./ImageViewer";
import Arrow from "src/assets/arrow.svg";
import { Euler, Quaternion } from "three";
import { radians_to_degrees } from "src/utils/utils";
import { Spin } from "antd";

type MapComponentProps = {
	width: number;
	height: number;
	map: Map;
	markerPosition?: Pose | undefined;
	zoom?: number;
	pinMarker?: boolean;
	draggable?: boolean;
	title?: string;
	scan?: LaserScanForMap | undefined;
};

const MapComponent: FC<MapComponentProps> = ({
	width,
	height,
	map,
	markerPosition,
	zoom = 1,
	pinMarker = true,
	draggable = false,
	title,
	scan,
}) => {
	const [stage, setStage] = useState<createjs.Stage>();
	let prevX = 0;
	let prevY = 0;
	let prevDistance = 0;
	let isZooming = false;

	useEffect(() => {
		setStage(new createjs.Stage("myCanvas"));
	}, []);

	useEffect(() => {
		if (!stage) return;
		const canvas = document.getElementById("myCanvas") as HTMLCanvasElement;

		createjs.Ticker.timingMode = createjs.Ticker.RAF_SYNCHED;
		createjs.Ticker.framerate = 60;
		createjs.Ticker.addEventListener("tick", stage);
		stage.x = width / 2;
		stage.y = height / 2;
		stage.scaleX = zoom;
		stage.scaleY = zoom;
		const img: any = new ImageViewer(map);
		const image: HTMLImageElement = img.getCanvas();
		const mapBitMap = new createjs.Bitmap(image);
		mapBitMap.name = "map";
		// Set Mapbitmap to map data origin
		mapBitMap.y = -map.info.height;
		mapBitMap.x = map.info.origin.position.x / map.info.resolution;
		mapBitMap.y =
			mapBitMap.y - map.info.origin.position.y / map.info.resolution;
		stage.addChild(mapBitMap);
		stage.update();

		if (draggable) {
			stage?.on("mousedown", function (evt: any) {
				const ct = evt.currentTarget,
					local = ct.globalToLocal(evt.stageX, evt.stageY),
					nx = ct.regX - local.x,
					ny = ct.regY - local.y;
				//set the new regX/Y
				ct.regX = local.x;
				ct.regY = local.y;
				//adjust the real-position, otherwise the new regX/Y would cause a jump
				ct.x -= nx;
				ct.y -= ny;
			});

			stage?.on("pressmove", function (evt: any) {
				const ct = evt.currentTarget;
				ct.x = evt.stageX;
				ct.y = evt.stageY;
			});

			canvas.addEventListener("wheel", handleWheelEvent);
			canvas.addEventListener("touchstart", handleTouchStart);
			canvas.addEventListener("touchmove", handleTouchMove);
			canvas.addEventListener("touchend", handleTouchEnd);
		}

		addMarker();
		return () => {
			stage.removeAllEventListeners();
			canvas.removeEventListener("touchstart", handleTouchStart);
			canvas.removeEventListener("touchmove", handleTouchMove);
			canvas.removeEventListener("touchend", handleTouchEnd);
			canvas.removeEventListener("wheel", handleWheelEvent);
		};
	}, [stage]);

	const handleTouchStart = (evt: TouchEvent) => {
		evt.preventDefault();
		if (evt.touches.length > 1) {
			isZooming = true;
			const x = evt.touches[1].clientX - evt.touches[0].clientX;
			const y = evt.touches[1].clientY - evt.touches[0].clientY;
			prevDistance = Math.sqrt(x * x + y * y);
		} else {
			prevX = evt.touches[0].clientX;
			prevY = evt.touches[0].clientY;
		}
	};

	const handleTouchMove = (evt: TouchEvent) => {
		if (!stage) return;

		const { length } = evt.touches;
		const deltaX = evt.touches[0].clientX - prevX,
			deltaY = evt.touches[0].clientY - prevY;

		if (length > 1) {
			if (isZooming) {
				const x = evt.touches[1].clientX - evt.touches[0].clientX;
				const y = evt.touches[1].clientY - evt.touches[0].clientY;
				const distance = Math.sqrt(x * x + y * y);
				const scaleDelta = (distance - prevDistance) * 0.01;
				const newScale = Math.min(Math.max(stage.scaleX + scaleDelta, 0.8), 2); // Limit the zoom level to between 0.5 and 2.
				stage.scaleX = stage.scaleY = newScale;
				prevDistance = distance;
			}
		} else if (!isZooming) {
			stage.x += deltaX;
			stage.y += deltaY;
		}

		stage.update();
		prevX = evt.touches[0].clientX;
		prevY = evt.touches[0].clientY;
	};

	const handleTouchEnd = () => {
		isZooming = false;
		prevX = 0;
		prevY = 0;
	};

	const handleWheelEvent = (evt: WheelEvent) => {
		if (!stage) return;
		evt.preventDefault();
		const scaleFactor = evt.deltaY > 0 ? 0.9 : 1.1;
		const newScale = Math.min(Math.max(stage.scaleX * scaleFactor, 0.5), 5); // Limit the zoom level to between 0.5 and 2.
		stage.scaleX = newScale;
		stage.scaleY = newScale;
		stage.update();
	};

	useEffect(() => {
		updateMarker();
	}, [markerPosition]);

	useEffect(() => {
		if (scan) {
			addScan(scan);
		}
	}, [scan]);

	const addMarker = () => {
		const marker = new Image();
		marker.src = Arrow;
		marker.onload = function (event) {
			const markerImg = event.target;
			if (markerImg && stage) {
				const markerBitmap = new createjs.Bitmap(markerImg);
				markerBitmap.scaleX = 0.5;
				markerBitmap.scaleY = 0.5;
				markerBitmap.x = 0;
				markerBitmap.y = 0;
				markerBitmap.regX = markerBitmap.image.width / 2;
				markerBitmap.regY = markerBitmap.image.height / 2;
				markerBitmap.name = "marker";
				stage.addChild(markerBitmap);
			}
		};
	};

	const updateMarker = () => {
		if (stage && markerPosition) {
			const { position, orientation } = markerPosition;
			const mapBitMap = stage.getChildByName("map");
			const markerBitMap = stage.getChildByName("marker");
			const container = stage.getChildByName("scan");
			const quaternion = new Quaternion(
				orientation.x,
				orientation.y,
				orientation.z,
				orientation.w
			);
			const rotation = new Euler().setFromQuaternion(quaternion, "XYZ");
			if (mapBitMap && markerBitMap) {
				markerBitMap.rotation = radians_to_degrees(rotation.z);
				if (pinMarker) {
					createjs.Tween.get(mapBitMap, { override: true }).to(
						{
							regX: position.x / map.info.resolution,
							regY: -position.y / map.info.resolution,
						},
						100,
						createjs.Ease.linear
					);
					if (container) {
						container.regX = position.x / map.info.resolution;
						container.regY = -position.y / map.info.resolution;
					}
				} else {
					createjs.Tween.get(markerBitMap, { override: true }).to(
						{
							x: position.x / map.info.resolution,
							y: -position.y / map.info.resolution,
						},
						100,
						createjs.Ease.linear
					);
				}
			}
		}
	};

	const addScan = (scan: LaserScanForMap) => {
		if (!stage) return;
		let container = stage.getChildByName("scan") as createjs.Container;
		const robot = stage.getChildByName("marker");
		const map = stage.getChildByName("map");
		if (robot) {
			if (!container) {
				container = new createjs.Container();
				container.name = "scan";
				stage.addChild(container);
			} else {
				container.removeAllChildren();
			}
			for (let i = 0; i < scan.data.length; i += 2) {
				const point = new createjs.Shape();
				point.graphics.beginFill("#2416e0");
				point.graphics.drawCircle(0, 0, 1);
				point.x = scan.data[i] / 0.05;
				point.y = -scan.data[i + 1] / 0.05;
				container.addChild(point);
			}
		}
	};

	return (
		<div
			className="bg-black overflow-hidden rounded-lg"
			style={{ touchAction: "none" }}
		>
			{title && (
				<h3 className=" text-xs bg-bg_secondary text-white px-2 py-1 font-medium">
					{title}
				</h3>
			)}
			{map !== undefined ? (
				<canvas
					id="myCanvas"
					className=""
					width={width}
					height={height}
				></canvas>
			) : (
				<Spin
					tip="Loading..."
					className="absolute top-1/2 left-1/2 z-10 -translate-x-1/2"
					size={draggable ? "large" : "default"}
				/>
			)}
		</div>
	);
};
export default MapComponent;
