import { FC, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Map } from "src/interface/device";
import { AlertCircle } from "react-feather";
import { useTelemetry } from "src/hooks/useTelemetry";

//Interfaces

//Services
import DeviceService from "src/services/user/devices";
import MapComponent from "./Map";

type RadarProps = {
	status: string;
};
const Radar: FC<RadarProps> = ({ status }) => {
	const { id }: { id: string } = useParams();
	const [map, setMap] = useState<Map | undefined>(undefined);
	const mapRef = useRef<HTMLDivElement>(null!);
	const { pose } = useTelemetry(id);

	useEffect(() => {
		getMapData();
	}, []);

	const getMapData = async () => {
		const res = await DeviceService.GetMap(id);
		if (res) {
			setMap(res.map);
		}
	};

	return (
		<div ref={mapRef} className="w-full h-full">
			{status !== "ONLINE" ? (
				<div className="flex items-center justify-center h-full ">
					<h1 className="text-gray-500 text-2xl flex items-center">
						<AlertCircle className="mr-2" /> Device is offline
					</h1>
				</div>
			) : (
				<>
					{map && (
						<MapComponent
							width={mapRef.current?.offsetWidth}
							height={mapRef.current?.offsetHeight}
							map={map}
							pinMarker={true}
							markerPosition={pose}
							draggable
						/>
					)}
				</>
			)}
		</div>
	);
};

export default Radar;
