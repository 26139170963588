import { useEffect, useState } from "react";
import { List } from "antd";

import { Device } from "src/interface/device";
import device from "../../../services/user/devices";
import { useHistory } from "react-router-dom";

import { MapPin, Clock } from "react-feather";
import config from "../../../config";

const Devices = () => {
	const history = useHistory();
	const [devices, setDevices] = useState<Device[]>([]);

	useEffect(() => {
		getDevices();
	}, []);

	const getDevices = async () => {
		const res = await device.list();
		if (res?.status && res?.data) {
			setDevices(res.data);
		}
	};

	const getState = (key: string, value: number): string => {
		return config[key][value];
	};

	return (
		<div>
			<List
				grid={{
					gutter: 16,
					xs: 1,
					sm: 2,
					md: 2,
					lg: 3,
					xl: 3,
					xxl: 4,
				}}
				dataSource={devices}
				renderItem={(item) => (
					<List.Item onClick={() => history.push(`/devices/${item.id}`)}>
						<div
							style={{ background: "#1E1E1E" }}
							className="p-6 device-card cursor-pointer"
						>
							<div className="flex justify-between items-center">
								<div className=" w-full">
									<div className="flex items-center mb-2 justify-between">
										<div className="flex items-center space-x-3 justify-between w-full">
											<h1 className="text-white font-normal text-lg ">
												{item.name}
											</h1>
											<button
												style={{ background: "#404040", fontSize: "12px" }}
												className="p-1 px-3 text-gray-400 text-s rounded-full capitalize"
											>
												{item.type?.toLocaleLowerCase()?.replaceAll("_", " ")}
											</button>
										</div>
										{/* <Battery percentage={item.battery_status} /> */}
									</div>
									<div className="flex items-center capitalize space-x-1">
										<p className="text-xs text-gray-300 flex items-center space-x-1">
											<div
												className={`w-2 h-2 bg-${
													item.status === "ONLINE" ? "green" : "red"
												}-500 rounded-full`}
											></div>
											<span>{item.status?.toLowerCase() || "Offline"}</span>
										</p>
										{/* <span className="text-gray-400">|</span>
										<p
											className={`text-xs captitalize text-${getColors(
												getState("device_state", item.device_state)
											)}-300`}
										>
											{getState("device_state", item.device_state)
												?.replaceAll("_", " ")
												.toLowerCase()}
										</p>
										<span className="text-gray-400">|</span>
										<p
											className={`text-xs captitalize text-${getColors(
												getState("application_state", item.application_state)
											)}-300`}
										>
											{getState("application_state", item.application_state)
												?.replaceAll("_", " ")
												.toLowerCase()}
										</p> */}
									</div>
								</div>
							</div>
							<div className="mt-12 flex justify-between">
								<div className="flex items-start space-x-1 flex-1">
									<MapPin size={15} style={{ color: "#FF838D" }} />
									<div>
										<p className="text-gray-300 text-xs mb-1">Location</p>
										<p className="text-base font-normal text-white">
											{item.location}
										</p>
									</div>
								</div>
								{/* <div className="flex items-start space-x-1 flex-1">
									<Clock size={15} style={{ color: "#FE954E" }} />
									<div>
										<p className="text-gray-300 text-xs mb-1">Runtime</p>
										<p className="text-base font-normal text-white">
											{item.run_time ?? "-"}
										</p>
									</div>
								</div> */}
								<div className="flex items-start space-x-1 flex-1">
									{item?.assignments?.[0]?.run?.tasks?.[0]?.room?.name && (
										<>
											<Clock size={15} style={{ color: "#FE954E" }} />
											<div>
												<p className="text-gray-300 text-xs mb-1">Room No</p>
												<p className="text-base font-normal text-white">
													{item?.assignments?.[0]?.run?.tasks?.[0]?.room?.name}
												</p>
											</div>
										</>
									)}
								</div>
							</div>
						</div>
					</List.Item>
				)}
			/>
		</div>
	);
};

export default Devices;
