import React from "react";
import OrderList from "src/components/user/order/OrderList";
import { WithPermission } from "src/utils/utils";

function Home() {
	return (
		<>
			<div className="p-4 rounded-lg bg--secondary ">
				<OrderList />
			</div>
		</>
	);
}

export default Home;
