import { useEffect, useState, createRef, Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";

import {
	CheckOutlined,
	CaretDownOutlined,
	CloseOutlined,
} from "@ant-design/icons";

interface IOption {
	label: string;
	value: string;
	description?: string;
}

interface IProps {
	onChange: (value: string[] | string) => void;
	options?: IOption[] | undefined;
	placeholder?: string;
	color?: string;
	value?: IOption[] | undefined;
	selectedValue?: string | undefined;
	multiple?: boolean;
}

const MultiSelect = ({
	onChange,
	options,
	placeholder = "Select an option",
	color = "indigo",
	value,
	multiple,
	selectedValue,
}: IProps) => {
	const [selected, setSelected] = useState<IOption[]>(value ? value : []);
	const [single, setSingle] = useState<string | undefined>(selectedValue);
	const buttonRef: any = createRef();

	useEffect(() => {
		setSingle(selectedValue);
	}, [selectedValue]);

	useEffect(() => {
		if (multiple) {
			onChange(selected.map((d) => d.value));
		} else {
			single && onChange(single);
		}
	}, [selected, single]);

	const handleChange = (value: string) => {
		if (options) {
			if (multiple) {
				const index = selected.findIndex((d) => d.value === value);
				const selectedDevices = [...selected];

				if (index >= 0) {
					selectedDevices.splice(index, 1);
					setSelected(selectedDevices);
				}
				if (index === -1) {
					const device = options.find((o) => o.value === value);
					if (device) {
						setSelected([...selectedDevices, device]);
					}
				}
			} else {
				const index = options.findIndex((d) => d.value === value);
				if (index >= 0) {
					setSingle(options[index].value);
				}
			}
		}
	};

	const checkSelected = (value: string) => {
		if (multiple) {
			return selected.some((d) => d.value === value);
		}
		return single === value;
	};

	const changeFocus = () => {
		if (buttonRef.current) {
			buttonRef?.current?.focus();
		}
	};

	return (
		<div>
			<Listbox value="Development Device" onChange={handleChange}>
				{() => (
					<>
						<div className="relative">
							<Listbox.Button
								ref={buttonRef}
								style={{ padding: "0.6rem" }}
								className={`relative w-full pr-10 text-left input--primary rounded-md cursor-pointer focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-orange-300 focus-visible:ring-offset-2 focus-visible:border-${color}-500 sm:text-sm`}
							>
								<span className="block truncate">
									{!multiple ? (
										<span>
											{single ? (
												options?.find((option) => option.value === single)
													?.label
											) : (
												<span className="text-gray-400">{placeholder}</span>
											)}
										</span>
									) : (
										<>
											{selected.length ? (
												<div className="flex space-x-1">
													{selected.map((option, index) => {
														if (index < 4) {
															return (
																<div
																	key={index}
																	className={`border border-${color}-500 text-${color}-500 font-semibold rounded-md px-2 py-1 flex items-center`}
																	style={{
																		width: "fit-content",
																	}}
																>
																	<span> {option?.label?.substring(0, 6)}</span>
																	<span
																		className="flex items-center ml-1"
																		onClick={() => handleChange(option.value)}
																	>
																		<CloseOutlined />
																	</span>
																</div>
															);
														}
													})}
													{selected.length > 5 && (
														<div
															className={`bg-${color}-50 text-${color}-600 font-semibold rounded-md px-2 py-1 flex items-center`}
															style={{
																width: "fit-content",
															}}
														>
															+{selected.length - 5}
														</div>
													)}
												</div>
											) : (
												<span className="text-gray-400">
													{placeholder ? placeholder : "Select an option"}
												</span>
											)}
										</>
									)}
								</span>

								<span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
									<CaretDownOutlined className="w-5 h-5 text-gray-400 pt-1" />
								</span>
							</Listbox.Button>
							<Transition
								as={Fragment}
								leave="transition ease-in duration-100"
								leaveFrom="opacity-100"
								leaveTo="opacity-0"
							>
								<Listbox.Options
									onMouseOver={() => changeFocus()}
									className="absolute w-full py-1 mt-1 z-50 max-h-40 overflow-auto text-base input--primary rounded-md shadow-lg  ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
								>
									{options?.map((o, index) => (
										<Listbox.Option
											key={index}
											className={({ active }) =>
												`${
													active || checkSelected(o.value)
														? `bg--gray`
														: "text-white"
												}
                           select-none relative py-2 pl-10 pr-4 cursor-pointer text-sm`
											}
											value={o.value}
										>
											{({ active }) => {
												const isSelected = checkSelected(o.value);
												return (
													<div>
														<>
															<span
																className={`${
																	isSelected ? "font-medium" : "font-normal"
																} block truncate`}
															>
																{o.label}
															</span>
															{isSelected ? (
																<span
																	className={`${
																		active
																			? `text-${color}-600`
																			: `text-${color}-600`
																	}
                                absolute inset-y-0 left-0 flex items-center pl-3 pt-1`}
																>
																	<CheckOutlined
																		className="w-5 h-5"
																		aria-hidden="true"
																	/>
																</span>
															) : null}
														</>
														<div className="text-sm ">{o.description}</div>
													</div>
												);
											}}
										</Listbox.Option>
									))}
								</Listbox.Options>
							</Transition>
						</div>
					</>
				)}
			</Listbox>
		</div>
	);
};

export default MultiSelect;
