import { Resp } from "src/interface/common";
import { User, UserUpdate } from "src/interface/user/user";
import request from "src/utils/request";

const { REACT_APP_API_HOST: HOST } = process.env;

const Profile = (): Promise<Resp<User>> => {
	return request(`${HOST}/api/v1/users/me`, {
		method: "GET",
		headers: {
			Authorization: `Bearer ${localStorage.getItem("access_token")}`,
		},
	});
};

const Update = (data: UserUpdate): Promise<Resp<User>> => {
	return request(`${HOST}/api/v1/users`, {
		method: "PATCH",
		headers: {
			Authorization: `Bearer ${localStorage.getItem("access_token")}`,
		},
		data,
	});
};

export type FcmData = {
	first_name: string;
	id: string;
};

export const UpdateFcm = (token: string): Promise<FcmData> => {
	return request(`${HOST}/api/v1/users/fcm`, {
		method: "PATCH",
		headers: {
			Authorization: `Bearer ${localStorage.getItem("access_token")}`,
		},
		data: {
			token,
		},
	});
};

export const SubscribeFcm = (token: string): Promise<FcmData> => {
	return request(`${HOST}/api/v1/users/fcm/subscribe`, {
		method: "POST",
		headers: {
			Authorization: `Bearer ${localStorage.getItem("access_token")}`,
		},
		data: {
			token,
		},
	});
};

export const UnsubscribeFcm = (token: string): Promise<FcmData> => {
	return request(`${HOST}/api/v1/users/fcm/unsubscribe`, {
		method: "POST",
		data: {
			token,
		},
	});
};

export default { Profile, Update, UpdateFcm, SubscribeFcm, UnsubscribeFcm };
