const config: {
	[key: string]: any;
} = {
	permissions: [
		"read:categories",
		"create:categories",
		"list:categories",
		"delete:categories",
		"update:categories",
		"create:locations",
		"read:locations",
		"list:locations",
		"delete:locations",
		"update:locations",
		"create:guests",
		"read:guests",
		"list:guests",
		"create:orders",
		"read:orders",
		"list:orders",
		"create:products",
		"read:products",
		"list:products",
		"delete:products",
		"update:products",
		"list:reports",
		"update:reports",
		"create:rooms",
		"list:rooms",
		"delete:rooms",
		"update:rooms",
		"create:room_types",
		"list:room_types",
		"delete:room_types",
		"update:room_types",
		"list:devices",
		"read:devices",
	],
	device_state: {
		0: "IDLE",
		1: "READY",
		2: "WORKING",
		3: "MAPPING",
		4: "PATH_RECORDING",
		5: "HOMING",
		6: "GOING_FOR_CHARGE",
		7: "DOCKING",
		8: "CHARGING",
		9: "CHARGING_READY",
		10: "MANUAL_CHARGING",
		11: "LOW_BATTERY",
		12: "EMERGENCY",
	},
	application_state: {
		0: "NOT_READY",
		1: "READY",
		2: "WORKING",
		3: "PAUSED",
		4: "EMERGENCY",
		5: "HOMING",
	},
};

export default config;
