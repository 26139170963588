// src/auth/auth0-provider-with-history.js

import React, { ReactChild } from "react";
import { useHistory } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import config from "src/config";

const Auth0ProviderWithHistory = ({ children }: { children: ReactChild }) => {
	const history = useHistory();
	const domain = process.env.REACT_APP_AUTH0_DOMAIN;
	const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
	const audience = process.env.REACT_APP_AUTH0_HOTEL_ROBOT_AUDIENCE;

	const onRedirectCallback = (appState: any) => {
		history.push(appState?.returnTo || window.location.pathname);
	};
	console.log(clientId, domain, audience);

	return (
		<>
			{domain && clientId && (
				<Auth0Provider
					domain={domain}
					clientId={clientId}
					redirectUri={window.location.origin}
					onRedirectCallback={onRedirectCallback}
					audience={audience}
					scope={config.permissions.join(" ")}
				>
					{children}
				</Auth0Provider>
			)}
		</>
	);
};

export default Auth0ProviderWithHistory;
