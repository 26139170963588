import React from "react";
import { Table } from "antd";
import { RoomType, RoomtypeProps } from "src/interface/user/room";

const RoomtypesList = ({ roomtypes, onRow }: RoomtypeProps.List) => {
	const column = [
		{
			title: "Name",
			key: "name",
			align: "left" as const,

			render: ({ name }: RoomType) => (
				<p className="m-0 font-semibold">{name}</p>
			),
		},
		{
			title: "Description",
			key: "description",
			align: "center" as const,

			render: ({ description }: RoomType) => (
				<p className="m-0 font-medium truncate">{description ?? "-"}</p>
			),
		},
		{
			title: "Rent per hour",
			key: "rent_per_hour",
			align: "center" as const,

			render: ({ rent_per_hour }: RoomType) => (
				<p className="m-0 font-medium">{rent_per_hour ?? 0}</p>
			),
		},
	];
	return (
		<div>
			<Table
				columns={column}
				dataSource={roomtypes}
				rowClassName="cursor-pointer header-center"
				onRow={(row) => {
					return {
						onClick: () => onRow(row),
					};
				}}
			/>
		</div>
	);
};

export default RoomtypesList;
