import React from "react";
import ReactECharts from "echarts-for-react";
import { PiechartProps } from "src/interface/user/report";

const PieChart = ({ data, style, radius }: PiechartProps) => {
	const pieOption = {
		tooltip: {
			trigger: "item",
		},
		series: [
			{
				name: "",
				type: "pie",
				radius: radius,
				label: {
					position: "outside",
					color: radius === "70%" ? "black" : "white",
					fontSize: 15,
					fontFamily: "poppins",
					formatter: "{b} {c}",
				},
				labelLine: {
					smooth: true,
				},
				data: [
					{
						value: data.success,
						name: "Success",
						itemStyle: {
							color: "#f9613c",
						},
					},
					{
						value: data.failed,
						name: "Failed",
						itemStyle: {
							color: "#e92e3d",
						},
					},
				],
			},
		],
	};

	return (
		<div>
			<ReactECharts
				option={pieOption}
				opts={{ renderer: "svg" }}
				style={style}
			/>
			<div
				className={`text-lg ${radius === "70%" ? "text-black" : "text-white"}`}
			>
				Total Orders : {data.success + data.failed}
			</div>
		</div>
	);
};
export default PieChart;
