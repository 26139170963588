import React from "react";
import { Menu, Transition } from "@headlessui/react";
import { Fragment, ReactChild, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
// import { ChevronDownIcon } from "@heroicons/react/solid";

export type menu = {
	title: string;
	path?: string;
	onClick?: () => void;
	color?: string;
	icon?: JSX.Element;
	description?: string;
	extra?: string;
};

export interface IDropDownProps {
	children: ReactChild;
	menu: menu[];
	title?: string;
	width?: number;
	footer?: JSX.Element;
}

export default function Dropdown({
	children,
	menu,
	title,
	width = 100,
	footer,
}: IDropDownProps) {
	const history = useHistory();
	return (
		<Menu as="div" className="relative inline-block text-left">
			<div className="flex items-center">
				<Menu.Button className="focus:outline-none">{children}</Menu.Button>
			</div>
			<Transition
				as={Fragment}
				enter="transition ease-out duration-100"
				enterFrom="transform opacity-0 scale-95"
				enterTo="transform opacity-100 scale-100"
				leave="transition ease-in duration-75"
				leaveFrom="transform opacity-100 scale-100"
				leaveTo="transform opacity-0 scale-95"
			>
				<Menu.Items
					style={{
						width: `${width}px`,
						maxHeight: "300px",
					}}
					className="absolute right-0 z-10 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
				>
					<div
						className="px-2 py-2 flex flex-col "
						style={{ maxHeight: "300px" }}
					>
						{title && (
							<Menu.Item>
								{() => (
									<div
										className="ml-2 group flex rounded-md items-center w-full px-2 py-2 text-sm"
										style={{ height: "30px" }}
									>
										{title}
									</div>
								)}
							</Menu.Item>
						)}
						<div className="overflow-y-auto" style={{ height: "100%" }}>
							{menu.map((m, i) => (
								<Menu.Item key={i}>
									{({ active }) => (
										<button
											onClick={() => {
												if (m.path) {
													history.push(m.path);
												} else if (m.onClick) {
													m.onClick();
												}
											}}
											className={`${
												m.description ? "items-start" : "items-center"
											} ${
												active &&
												`${m.color ? `bg-${m.color}-500` : "bg-gray-200"} ${
													m.color
														? "text-white"
														: m.color
														? `text-${m.color}-500`
														: "text-gray-900"
												} `
											} group flex rounded-md w-full px-2 py-2 text-sm `}
										>
											<span
												className={`mr-3 ml-2 ${m.description && "mt-1"}`}
												style={{ lineHeight: 0 }}
											>
												{m.icon}
											</span>
											{m.description ? (
												<div className="text-left">
													<p className="font-semibold">{m.title}</p>
													<p className="text-xs">{m.description}</p>
												</div>
											) : (
												<p>{m.title}</p>
											)}
										</button>
									)}
								</Menu.Item>
							))}
						</div>
						{footer && (
							<Menu.Item>
								{() => (
									<button
										className={` group flex rounded-md w-full px-2 py-2 text-sm text-gray-900 items-center justify-end`}
									>
										{footer}
									</button>
								)}
							</Menu.Item>
						)}
					</div>
				</Menu.Items>
			</Transition>
		</Menu>
	);
}
