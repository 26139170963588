import Poor from "src/assets/emoji/Poor.svg";
import Fair from "src/assets/emoji/Fair.svg";
import Average from "src/assets/emoji/Average.svg";
import Good from "src/assets/emoji/Good.svg";
import Excellent from "src/assets/emoji/Excellent.svg";
import { ReactElement } from "react";

import { decodePayload } from "./auth";

export const subString = (content: string | undefined, char: number) => {
	if (content) {
		if (content.length < char) {
			return content.substring(0, char);
		} else {
			return `${content.substring(0, char)}...`;
		}
	}
};

// Map user feedback number to string.
export const userFeedbackMap = (rating: number): [ReactElement, string] => {
	switch (rating) {
		case 1:
			return [
				<img key={rating} src={Poor} alt="" className="w-8 mr-2" />,
				"Poor",
			];
		case 2:
			return [
				<img key={rating} src={Fair} alt="" className="w-8 mr-2" />,
				"Fair",
			];
		case 3:
			return [
				<img key={rating} src={Average} alt="" className="w-8 mr-2" />,
				"Average",
			];
		case 4:
			return [
				<img key={rating} src={Good} alt="" className="w-8 mr-2" />,
				"Good",
			];
		case 5:
			return [
				<img key={rating} src={Excellent} alt="" className="w-8 mr-2" />,
				"Excellent",
			];
		default:
			return [<img key={rating} src={Poor} alt="" className="w-8 mr-2" />, ""];
	}
};

export const formatFailureReason = (action: number, info: any): string => {
	switch (action) {
		case 0:
			return "One or more items unavailable";
		case 1:
			return "Insufficent storage space";
		case 2:
			return `There was a problem while going to ${info}`;
		case 3:
			return "There was a problem sending order info to the Android device";
		case 4:
			return `No user interacted at the time of ${info.type}`;
		case 5:
			return "Problem with otp verification, Probably the guest might have entered wrong otp or verification timeout might have exceeded";
		case 6:
			return `No one has confirmed at the time of ${info.type}`;
		case 7:
			return "Timeout has occured at the time of user feedback";
		default:
			return "Failure reason unavailable";
	}
};

export const getColors = (status: string) => {
	switch (status) {
		case "IDLE":
			return "blue";
		case "DELIVERY":
			return "green";
		case "LOW_BATTERY":
			return "red";
		case "PICKUP":
			return "orange";
		case "DOCKING":
			return "blue";
		case "IN_SERVICE":
			return "orange";
		case "ONLINE":
			return "green";
		case "OFFLINE":
			return "RED";
		default:
			return "blue";
	}
};

// function to check weather the user has permission to perform the action by checking the permissions array and return true or false
export const hasPermission = (permission: string): boolean => {
	const user = decodePayload();
	if (
		user &&
		(user?.permissions?.some((p) => p.indexOf(permission) !== -1) ||
			user?.permissions?.includes("super.admin"))
	) {
		return true;
	}
	return false;
};

// create a higher order component which will check the permissions and return the children if the user has the permission
export const WithPermission = ({
	permission,
	children,
}: {
	permission: string;
	children: JSX.Element;
}) => {
	console.log("Permission", hasPermission(permission));
	return children;
	// return hasPermission(permission) ? children : <></>;
};

export function base64ToArrayBuffer(base64: string) {
	const binary_string = window.atob(base64);
	const len = binary_string.length;
	const bytes = new Uint8Array(len);
	for (let i = 0; i < len; i++) {
		bytes[i] = binary_string.charCodeAt(i);
	}
	return bytes.buffer;
}

export function radians_to_degrees(radians: number) {
	const pi = Math.PI;
	return radians * -1 * (180 / pi) + 90;
}
