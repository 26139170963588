import React from "react";
import Locations from "src/components/user/location";

const Location = () => {
	return (
		<>
			<Locations />
		</>
	);
};

export default Location;
