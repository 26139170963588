import { message, notification, Timeline } from "antd";
import { useState, useEffect } from "react";
import { DownloadOutlined, ReloadOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import { Order, UpdateOrderData } from "src/interface/order";
import orders from "src/services/user/order";
import download from "downloadjs";
import moment from "moment";

import {
	CheckCircleFilled,
	LoadingOutlined,
	CloseCircleFilled,
	ClockCircleFilled,
} from "@ant-design/icons";
import { userFeedbackMap } from "src/utils/utils";
import report from "src/services/user/report";
import { Select } from "antd";

const getIcons = (status: string) => {
	switch (status) {
		case "PENDING":
			return {
				color: "yellow",
				icon: <ClockCircleFilled style={{ color: "#f78e3d" }} />,
			};
		case "ASSIGNED":
			return {
				color: "blue",
				icon: null,
			};
		case "IN_PROGRESS":
			return {
				color: "blue",
				icon: <LoadingOutlined />,
			};
		case "CANCELLED":
			return {
				color: "red",
				icon: <CloseCircleFilled />,
			};
		case "COMPLETED":
			return {
				color: "green",
				icon: <CheckCircleFilled />,
			};
		case "FAILED":
			return {
				color: "red",
				icon: <CloseCircleFilled />,
			};
		default:
			return {
				color: "red",
				icon: null,
			};
	}
};

const OrderDetail = () => {
	const { id }: { id: string } = useParams();
	const [order, setOrder] = useState<Order>();
	const [deviceStatus, setDeviceStatus] = useState<string>("");
	const [reportStatus, setReportStatus] = useState<string>("OPEN");
	const [notificationKey, setNotificationKey] = useState<string | null>(null);
	let refreshInterval: NodeJS.Timeout;
	const REFRESH_INTERVAL = 15000;

	useEffect(() => {
		autoRefresh();

		return () => {
			clearInterval(refreshInterval);
			notificationKey && notification.close(notificationKey);
			setNotificationKey(null);
		};
	}, []);

	const updateOrder = async (data: UpdateOrderData) => {
		const res = await orders.Update(id, data);
		if (res?.status) {
			// setOrder(res.data);
			getOrder();
			message.success("Order updated successfully!");
		}
	};

	const autoRefresh = async () => {
		await getOrder();
		const interval = setInterval(async () => {
			const orderRes = await getOrder({
				device: true,
			});
			if (
				orderRes?.order_status === "FAILED" ||
				orderRes?.order_status === "COMPLETED"
			) {
				clearInterval(refreshInterval);
			}
		}, REFRESH_INTERVAL);
		refreshInterval = interval;
	};

	const getOrder = async (params?: any) => {
		const res = await orders.Detail(id, params);
		if (res?.status && res?.data) {
			setOrder(res.data);
			res.data.report && setReportStatus(res.data.report.status);
			if (res?.data?.device) {
				const device = res.data.device;

				setDeviceStatus((state) => {
					if (state !== device.status && device.status === "OFFLINE") {
						const key = `open${Date.now()}`;
						setNotificationKey((prevKey) => {
							if (prevKey) {
								notification.close(prevKey);
							}
							return key;
						});
						notification.warning({
							duration: null,
							message: `Oh Oh! ${device.name} went offline!`,
							placement: "bottomRight",
							key,
						});
					} else if (state !== device.status && device.status === "ONLINE") {
						const key = `open${Date.now()}`;
						setNotificationKey((prevKey) => {
							if (prevKey) {
								notification.close(prevKey);
							}
							return key;
						});
						notification.success({
							duration: 20,
							message: `${device.name} is online!`,
							placement: "bottomRight",
							key,
						});
					}

					return device.status;
				});
			}

			return res.data;
		}
	};

	const cancelOrder = async () => {
		const res = await orders.Cancel(id);
		if (res?.status && res?.data) {
			message.success("Order cancelled successfully");
			setOrder(res.data);
		}
	};

	const downloadReport = async (order: string) => {
		const name = `${order}-Report`;
		const res = await orders.GetReport(id);
		if (res) {
			download(res, `${name}.xlsx`);
		}
	};

	const changeReportStatus = async (status: string) => {
		if (order?.report.id) {
			const res = await report.Update(order?.report.id, {
				status,
			});
			if (res?.status && res?.data) {
				message.success("Report status updated successfully");
				setReportStatus(status);
				setOrder({
					...order,
					report: res.data,
				});
			}
		}
	};

	return (
		<div>
			{order && (
				<>
					<div className="flex justify-between mb-10">
						<h1 className="font-semibold text-lg text-white ">Order Details</h1>
						<div className="space-x-4 flex">
							<Select
								dropdownMatchSelectWidth={false}
								className="select--primary"
								options={[
									// { label: "Pending", value: "PENDING" },
									// { label: "In Progress", value: "IN_PROGRESS" },
									{ label: "Cancelled", value: "CANCELLED" },
									{ label: "Completed", value: "COMPLETED" },
									{ label: "Failed", value: "FAILED" },
								]}
								defaultValue={order.order_status}
								onChange={(value) =>
									updateOrder({
										order_status: value,
									})
								}
							/>
							<button
								className=" btn btn--primary text-xs py-2 flex items-center"
								onClick={() => downloadReport(order?.order_id)}
							>
								<DownloadOutlined className="mr-1" />
								Download Report
							</button>
						</div>
					</div>
					<div>
						{order && (
							<div className="space-y-8">
								<div className="rounded-card p-8 font-semibold  mx-auto ">
									<div className="grid grid-cols-12 gap-4">
										<div className="p-4 col-span-6 flex items-center">
											<span className=" flex-1">Order Id</span>
											<span className="font-light text-gray-300 flex-1">
												{order.order_id}
											</span>
										</div>
										<div className="p-4 col-span-6 flex items-center">
											<span className=" flex-1">Products</span>
											<span className="font-light text-gray-300 flex-1">
												{order.cart.length}
											</span>
										</div>
										<div className="p-4 col-span-6 flex items-center">
											<span className=" flex-1">Status</span>
											<span className="font-light text-gray-300 flex-1 flex items-center space-x-2">
												<span className="flex relative">
													<span
														className={`animate-ping absolute inline-flex h-2 w-2 rounded-full bg-${
															getIcons(order.order_status).color
														}-400 opacity-75`}
													></span>
													<span
														className={`relative inline-flex rounded-full h-2 w-2 bg-${
															getIcons(order.order_status).color
														}-500`}
													></span>
												</span>
												<span className="capitalize">
													{order.order_status.toLowerCase().replace("_", " ")}
												</span>
											</span>
										</div>
										<div className="p-4 col-span-6 flex items-center">
											<span className=" flex-1">Created</span>
											<span className="font-light text-gray-300 flex-1">
												{moment(order.created_at).calendar()}
											</span>
										</div>
										<div className="p-4 col-span-6 flex items-center">
											<span className=" flex-1">Room Name</span>
											<span className="font-light text-gray-300 flex-1">
												{order.room.name}
											</span>
										</div>
										<div className="p-4 col-span-6 flex items-center">
											<span className=" flex-1">Room No</span>
											<span className="font-light text-gray-300 flex-1">
												{order.room.room_number}
											</span>
										</div>
										<div className="p-4 col-span-6 flex items-center">
											<span className=" flex-1">Rating</span>
											<span className="font-light text-gray-300 flex-1">
												{order?.rating?.order_rating ? (
													<div className="space-x-2 flex items-center">
														<span>
															{userFeedbackMap(order.rating?.order_rating)[0]}
														</span>
														<p>
															{userFeedbackMap(order.rating?.order_rating)[1]}
														</p>
													</div>
												) : (
													<p>Guest has not given feedback yet!</p>
												)}
											</span>
										</div>
									</div>
								</div>
								<div className="rounded-card p-8 font-semibold  mx-auto">
									<h1 className="font-semibold text-lg text-white mb-4">
										Guest Details
									</h1>
									<div className="grid grid-cols-12 gap-4">
										<div className="p-4 col-span-6 flex items-center">
											<span className=" flex-1">Name</span>
											<span className="font-light text-gray-300 flex-1">
												{order.guest.name}
											</span>
										</div>
										<div className="p-4 col-span-6 flex items-center">
											<span className=" flex-1">Mobile</span>
											<span className="font-light text-gray-300 flex-1">
												{order.guest.mobile}
											</span>
										</div>
										<div className="p-4 col-span-6 flex items-center">
											<span className=" flex-1">Booking</span>
											<span className="font-light text-gray-300 flex-1">
												{order.guest.booking}
											</span>
										</div>
									</div>
								</div>
								<div className="rounded-card p-8   mx-auto">
									<div className="flex justify-between mb-3">
										<h1 className="font-semibold text-lg text-white mb-4">
											Order Status
										</h1>
										<button
											className="btn btn--primary text-xs py-2 flex items-center"
											onClick={() => getOrder()}
										>
											<ReloadOutlined className="mr-1" />
											Refresh
										</button>
									</div>
									{/* <Table pagination={false} columns={column} dataSource={order.tasks} rowClassName="cursor-pointer" /> */}
									<div>
										<Timeline>
											<Timeline.Item
												color={getIcons(order.order_status).color}
												dot={getIcons(order.order_status).icon}
											>
												{moment(order.created_at).format(
													"DD-MM-YYYY | hh:mm a"
												)}{" "}
												- Order({order.order_id}) to room{" "}
												{order.room.room_number} ({order.order_status})
											</Timeline.Item>
											{order.tasks.map((t, i) => (
												<Timeline.Item
													key={i}
													color={getIcons(t.task_status).color}
													dot={getIcons(t.task_status).icon}
													pending={
														order.order_status === "PENDING" ||
														order.order_status === "IN_PROGRESS"
													}
												>
													{moment(t.updated_at).format("DD-MM-YYYY | hh:mm a")}{" "}
													- Go to {t.room.name} for {t.task_type} (
													{t.task_status})
												</Timeline.Item>
											))}
										</Timeline>
										{order.order_status === "FAILED" && (
											<p>
												Failure Reason:{" "}
												{order.failure_reason ?? "Not Available"}
											</p>
										)}
									</div>
								</div>

								{order.report && (
									<div className="rounded-card p-8 font-semibold  mx-auto">
										<div className="flex justify-between">
											<h1 className="font-semibold text-lg text-white mb-4">
												Report
											</h1>
											<div>
												<Select
													dropdownMatchSelectWidth={false}
													className="select--primary"
													options={[
														{ label: "Open", value: "OPEN" },
														{ label: "In Progress", value: "IN_PROGRESS" },
														{ label: "Closed", value: "CLOSED" },
													]}
													defaultValue={order.report.status}
													onChange={(e) => changeReportStatus(e)}
												/>
											</div>
										</div>
										<div className="grid grid-cols-12 gap-4">
											<div className="p-4 col-span-12 flex items-center">
												<span className=" flex-1">Title</span>
												<span
													className="font-light text-gray-300"
													style={{ flex: 3 }}
												>
													{order.report.title}
												</span>
											</div>
											<div className="p-4 col-span-12 flex items-center">
												<span className=" flex-1">Description</span>
												<span
													className="font-light text-gray-300"
													style={{ flex: 3 }}
												>
													{order.report.message}
												</span>
											</div>
										</div>
									</div>
								)}
							</div>
						)}
					</div>
				</>
			)}
		</div>
	);
};

export default OrderDetail;
export { getIcons };
