import React from "react";
import ReactECharts from "echarts-for-react";
import { PiechartProps } from "src/interface/user/report";
import { Empty } from "antd";

const AmenitiesGraph = ({ data, width }: PiechartProps) => {
	const len = data.amenities.length;
	const sortedArray = data.amenities.sort((a, b) =>
		a.quantity > b.quantity ? 1 : -1
	);

	const pieOption = {
		tooltip: {
			trigger: "item",
		},
		xAxis: [
			{
				type: "value",
				splitLine: false,
			},
		],
		yAxis: [
			{
				type: "category",
				data: sortedArray.map((i) => i.product_name),
				axisLabel: {
					color: width ? "black" : "white",
					fontSize: width ? 11 : 13,
					interval: 0,
				},
			},
		],
		series: [
			{
				name: "",
				barWidth: 20,
				type: "bar",
				data: sortedArray.map((i) => ({
					value: i.quantity,
					itemStyle: {
						color: "#d63254",
						borderRadius: [0, 5, 5, 0],
					},
				})),
				label: {
					position: "right",
					show: true,
					color: width ? "black" : "white",
				},
			},
		],
	};

	return (
		<>
			{(sortedArray.length && (
				<ReactECharts
					option={pieOption}
					opts={{ renderer: "svg" }}
					style={{
						height: `${
							len < 4
								? len === 3
									? "230px"
									: len === 2
									? "200px"
									: "100px"
								: `${
										len === 4
											? len * 65
											: len === 5
											? len * 60
											: len === 6
											? len * 50
											: len * 45
								  }px`
						} `,
						width: width ? 800 : "100%",
					}}
				/>
			)) || (
				<div>
					<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
				</div>
			)}
		</>
	);
};
export default AmenitiesGraph;
