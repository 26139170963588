import React from "react";
import { Table } from "antd";
import { LocationProps, Location } from "../../../interface/admin/location";
import { RoomProps } from "src/interface/user/room";
import { Room } from "src/interface/order";
import { Product, ProductProps } from "src/interface/product";

const ProductsList = ({ products, onRow }: ProductProps.List) => {
	const column = [
		{
			title: "Name",
			key: "name",
			align: "left" as const,

			render: ({ name }: Product) => (
				<p className="m-0 font-semibold">{name}</p>
			),
		},
		{
			title: "Description",
			key: "description",
			align: "center" as const,

			render: ({ description }: Product) => (
				<p className="m-0 font-medium">{description}</p>
			),
		},
		{
			title: "Price",
			key: "price",
			align: "center" as const,

			render: ({ price }: Product) => (
				<p className="m-0 font-medium">{price}</p>
			),
		},
		{
			title: "Quantity",
			key: "quantity",
			align: "center" as const,

			render: ({ quantity }: Product) => (
				<p className="m-0 font-medium">{quantity}</p>
			),
		},
		{
			title: "Status",
			key: "status",
			align: "center" as const,
			render: ({ quantity }: Product) => (
				<p className="m-0 font-medium">
					{quantity === 0 ? "Unavailable" : "Available"}
				</p>
			),
		},
		{
			title: "Product Code",
			key: "product_code",
			align: "center" as const,
			render: ({ code }: Product) => <p className="m-0 font-medium">{code}</p>,
		},
	];
	return (
		<div>
			<Table
				columns={column}
				dataSource={products}
				rowClassName="cursor-pointer header-center"
				onRow={(row) => {
					return {
						onClick: () => onRow(row),
					};
				}}
			/>
		</div>
	);
};

export default ProductsList;
