import { useAuth0 } from "@auth0/auth0-react";
import { Table, TablePaginationConfig } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Order } from "src/interface/order";
import order from "src/services/user/order";

import { getIcons } from "./OrderDetail";

interface Filters {
	start?: string;
	end?: string;
}

const OrderList = ({ start, end }: Filters) => {
	const history = useHistory();
	const [orders, setOrders] = useState<Order[]>([]);
	const [loading, setLoading] = useState(false);
	const [pagination, setPagination] = useState<TablePaginationConfig>({
		current: 1,
		pageSize: 10,
	});
	const { getAccessTokenSilently } = useAuth0();

	useEffect(() => {
		getOrders(pagination);
	}, []);

	const getOrders = async (params: TablePaginationConfig) => {
		const accessToken = await getAccessTokenSilently({
			audience: process.env.REACT_APP_AUTH0_HOTEL_ROBOT_AUDIENCE,
			scope: "read:orders",
		});
		setLoading(true);
		const res = await order.List(accessToken, {
			sort: "desc",
			offset: params.current,
			limit: params.pageSize,
			start,
			end,
		});
		if (res?.status && res?.data) {
			setOrders(res.data.orders);
			setPagination({
				...params,
				total: res.data.total,
			});
			setLoading(false);
		}
	};

	const column = [
		{
			title: "Order ID",
			key: "order_id",
			align: "center" as const,
			render: (record: Order) => {
				return record.order_id;
			},
		},
		{
			title: "Robot",
			key: "room_no",
			align: "center" as const,

			render: (record: any) => {
				return record?.assignments[0]?.device?.name ?? "Unassigned";
			},
		},
		{
			title: "Room No",
			key: "room_no",
			align: "center" as const,

			render: (record: Order) => {
				return record.room.room_number;
			},
		},
		{
			title: "Guest Name",
			key: "guest",
			align: "center" as const,

			render: (record: Order) => {
				return record.guest.name;
			},
		},
		{
			title: "Booking ID",
			key: "booking_id",
			align: "center" as const,

			render: (record: Order) => {
				return record.guest.booking;
			},
		},
		{
			title: "Date",
			key: "date_and_time",
			align: "center" as const,

			render: (record: Order) => {
				return moment(record.created_at).calendar();
			},
		},
		{
			title: "Status",
			key: "status",
			align: "left" as const,

			render: (record: Order) => {
				return (
					<span className="capitalize flex items-center ">
						<div
							className={`w-2 h-2 bg-${
								getIcons(record.order_status).color
							}-500 rounded-full mr-1`}
						></div>
						{record.order_status.toLowerCase().replace("_", " ")}
					</span>
				);
			},
		},
	];

	const onPagination = (pagination: TablePaginationConfig) => {
		getOrders(pagination);
	};

	return (
		<Table
			columns={column}
			dataSource={orders}
			pagination={pagination}
			onChange={onPagination}
			rowClassName="cursor-pointer"
			loading={loading}
			onRow={(row) => {
				return {
					onClick: () => history.push(`/orders/${row.id}`),
				};
			}}
		/>
	);
};

export default OrderList;
