import { AutoComplete, Select } from "antd";
import React, { useState } from "react";
import debounce from "lodash/debounce";
import { Guest } from "src/interface/guest";

const { Option } = Select;

interface Option {
	label: string;
	value: string;
}

interface Props {
	fetchOptions: (value: string) => Promise<Guest[]>;
	debounceTimeout?: number;
	onChange: (value: Guest | string) => void;
	placeholder?: string;
	value: string;
}

const SearchInput = ({
	fetchOptions,
	debounceTimeout = 800,
	onChange,
	placeholder,
	value,
}: Props) => {
	const [options, setOptions] = useState<Guest[]>([]);
	const [fetching, setFetching] = React.useState(false);
	const fetchRef = React.useRef(0);

	const debounceFetcher = React.useMemo(() => {
		const loadOptions = (value: string) => {
			fetchRef.current += 1;
			const fetchId = fetchRef.current;
			setOptions([]);
			setFetching(true);

			fetchOptions(value).then((newOptions: Guest[]) => {
				if (fetchId !== fetchRef.current) {
					// for fetch callback order
					return;
				}

				setOptions(newOptions);
				setFetching(false);
			});
		};

		return debounce(loadOptions, debounceTimeout);
	}, [fetchOptions, debounceTimeout]);

	return (
		<AutoComplete
			value={value}
			className="input--primary p-1 border-0 text-white w-full"
			placeholder={placeholder}
			options={options.map((o) => ({ label: o.booking, value: o.booking }))}
			onSearch={debounceFetcher}
			onChange={(value: string) => {
				const guest = options.find((o) => o.booking === value);
				if (guest) {
					onChange(guest);
				} else {
					onChange(value);
				}
			}}
		/>
	);
};

export default SearchInput;
