import React from "react";
import DeviceDetails from "src/components/user/device/DeviceDetails";

const DeviceDetail = () => {
	return (
		<div>
			<DeviceDetails />
		</div>
	);
};

export default DeviceDetail;
