import jwt from "jsonwebtoken";
import { getPk } from "../services/auth";

export interface IPayload {
	id: string;
	permissions: string[];
	email: string;
}

export function isAuthenticated(): boolean {
	const pk = window.localStorage.getItem("pk");
	const access_token = window.localStorage.getItem("access_token");
	if (!pk || !access_token) {
		return false;
	}

	const data = jwt.decode(access_token);

	if (data) return true;

	return false;
}

export function decodePayload(): IPayload | null {
	const token = localStorage.getItem("access_token");
	if (token === null) {
		return null;
	}
	return jwt.decode(token) as IPayload;
}
