import { Col, Form, Input, Row, message } from "antd";
import { FC, useEffect } from "react";
import { PlusOutlined } from "@ant-design/icons";
import Select from "src/components/common/Select";

import countries from "src/utils/countries.json";
import { isArray } from "lodash";

//Interfaces

//Services
import GuestService from "src/services/user/guest";

//Components
import Modal from "src/components/common/Modal";
import { Guest } from "src/interface/guest";

type CreateGuestProps = {
	selected: Guest | undefined;
	onCreate: () => void;
	modal: boolean;
	toggle: () => void;
};
const CreateGuest: FC<CreateGuestProps> = ({
	selected,
	toggle,
	onCreate,
	modal,
}) => {
	const [form] = Form.useForm();

	useEffect(() => {
		if (selected) {
			toggleModel();
			form.setFieldsValue({
				name: selected.name,
				mobile: selected.mobile,
				country_code: selected.country_code,
				booking: selected.booking,
			});
		}
	}, [selected]);

	const onSubmit = async (values: any) => {
		values.country_code = parseInt(values.country_code);
		if (!selected) {
			const res = await GuestService.Create(values);
			if (res.status && res.data) {
				onCreate();
				message.success("Guest created");
			}
		} else {
			const res = await GuestService.Update(selected.id, values);
			if (res.status && res.data) {
				onCreate();
				message.success("Guest updated");
			}
		}
		toggleModel();
	};

	const toggleModel = () => {
		form.resetFields();
		toggle();
	};

	return (
		<div>
			<div className="flex justify-end mb-4">
				<button
					className="py-2 px-6 text-sm leading-5 font-medium text-white rounded-md  flex items-center btn btn--border "
					onClick={toggleModel}
				>
					<PlusOutlined className="mr-1 " /> Create Guest
				</button>
			</div>
			<Modal
				title={selected ? "Update Guest" : "Create Guest"}
				onCancel={toggleModel}
				size={40}
				open={modal}
				footer={
					<div className="flex justify-end w-full">
						<div className="space-x-3 ">
							<button
								className="btn btn--border py-1 px-6"
								onClick={toggleModel}
							>
								Cancel
							</button>
							<button
								className="btn btn--primary py-1 px-6"
								form="create_product_form"
								type="submit"
							>
								{selected ? "Update" : "Create"}
							</button>
						</div>
					</div>
				}
			>
				<Form
					className="space-y-4"
					form={form}
					layout="vertical"
					name="create_product_form"
					initialValues={{
						name: "",
						blocked: false,
					}}
					onFinish={onSubmit}
				>
					<Form.Item
						label="Name"
						name="name"
						rules={[{ required: true, message: "Name is required" }]}
					>
						<Input className="input--primary p-2 border-0 text-white"></Input>
					</Form.Item>
					<Form.Item
						label="Booking ID"
						name="booking"
						rules={[
							{
								required: true,
								message: "Booking ID is required!",
							},
							{
								pattern: new RegExp(/^[0-9A-Z]+$/),
								message: "Booking ID should be A-Z,0-9",
							},
						]}
					>
						<Input className="input--primary p-2 border-0 text-white"></Input>
					</Form.Item>
					<Row gutter={[12, 0]}>
						<Col span={8}>
							<Form.Item
								name="country_code"
								label="Country code"
								rules={[
									{
										required: true,
										message: "Country code is required!",
									},
								]}
							>
								<Select
									onChange={(value) => {
										if (value && !isArray(value)) {
											form.setFieldsValue({ country_code: value });
										}
									}}
									placeholder="Country Code"
									multiple={false}
									options={countries.map((c) => {
										return {
											label: `${c.name} (${c.dial_code})`,
											value: c.dial_code,
										};
									})}
									selectedValue={
										selected?.country_code ? `${selected?.country_code}` : ""
									}
								/>
							</Form.Item>
						</Col>
						<Col span={16}>
							<Form.Item
								name="mobile"
								label="Mobile"
								rules={[
									{
										required: true,
										message: "Phone number is required!",
									},
								]}
							>
								<Input
									autoComplete="off"
									type="number"
									placeholder="Phone Number (65 xxx xxxx)"
									className="input--primary p-2 border-0 text-white number w-full"
								/>
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</Modal>
		</div>
	);
};

export default CreateGuest;
