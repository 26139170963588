import { useState } from "react";
import { useEffect } from "react";

import { useParams } from "react-router-dom";
import { Device } from "src/interface/device";
import deviceService from "src/services/user/devices";
import { getColors } from "src/utils/utils";
import BatteryStatus from "./BatteryStatus";
import { robotState } from "src/config/constants";
import Radar from "./Radar";

const DeviceDetails = () => {
	const { id }: { id: string } = useParams();
	const [device, setDevice] = useState<Device>();
	const [telemetry, setTelemetry] = useState({
		battery: 0,
		state: 0,
	});

	useEffect(() => {
		getDevice();
		getLatestTelemetry();
	}, []);

	const getDevice = async () => {
		const res = await deviceService.Detail(id);
		if (res.status && res.data) {
			setDevice(res.data);
		}
	};

	const getLatestTelemetry = async () => {
		const res = await deviceService.GetLatestTelemetry(id);
		if (res.status && res.data) {
			console.log(res.data);
			setTelemetry({
				battery: res?.data?.agent?.battery?.value ?? 0,
				state: res?.data?.agent?.plan_state?.state ?? 13,
			});
		}
	};

	return (
		<div>
			<h1 className="text-white text-2xl font-normal mb-6">{device?.name}</h1>
			{device && (
				<div>
					<div className="rounded-card p-8 my-5 font-semibold mx-auto">
						<div className="grid grid-cols-12 gap-4">
							<div className="p-4 col-span-6 flex items-center">
								<span className=" flex-1">Robot Name</span>
								<span className="font-light flex-1">{device.name}</span>
							</div>
							<div className="p-4 col-span-6 flex items-center">
								<span className=" flex-1">Robot Type</span>
								<span className="font-light flex-1 capitalize">
									{device.type.replaceAll("_", " ").toLowerCase()}
								</span>
							</div>
							<div className="p-4 col-span-6 flex items-center">
								<span className=" flex-1">Status</span>
								<span className="font-light flex-1 ">
									<div
										style={{ backgroundColor: getColors(device.status) }}
										className="w-3 h-3 rounded-full inline-block  "
									></div>
									<div className="inline-block ml-2 capitalize">
										{device.status?.toLowerCase()}
									</div>
								</span>
							</div>
							<div className="p-4 col-span-6 flex items-center">
								<span className=" flex-1">Location</span>
								<span className="font-light flex-1">
									{device.location || "-"}
								</span>
							</div>
							<div className="p-4 col-span-6 flex items-center">
								<span className=" flex-1">Battery</span>
								<span className="font-light flex-1">{telemetry?.battery}%</span>
							</div>
							<div className="p-4 col-span-6 flex items-center">
								<span className=" flex-1">State</span>
								<span className="font-light flex-1">
									{robotState[telemetry?.state]}
								</span>
							</div>
						</div>
					</div>
					<BatteryStatus id={id} />
					<div className="mb-8 h-40">
						<div
							// ref={ref}
							id="canvas-container"
							className=" bg--secondary p-4 rounded-md relative mt-4"
						>
							<h1 className="text-xl font-normal text-white mb-3 ">
								{"Radar View"}
							</h1>
							<div
								className=" bg--secondary overflow-auto "
								style={{ height: "50vh", background: "#131313" }}
							>
								<Radar status={device.status} />
							</div>
							{/* {!pose && device.status === "ONLINE" && (
				<div className="absolute text-white bottom-6 right-10">
					<LoadingOutlined className="mr-2" />
					Loading robot pose
				</div>
			)} */}
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default DeviceDetails;
