import { Resp } from "src/interface/common";
import { CreateGuestData, Guest } from "src/interface/guest";
import request from "../../utils/request";

const { REACT_APP_API_HOST: HOST } = process.env;

type ListGuestParams = {
	booking_id?: string;
};
const list = (params?: ListGuestParams): Promise<Resp<Guest[] | []>> => {
	return request(`${HOST}/api/v1/guests`, {
		method: "GET",
		params,
		headers: {
			Authorization: `Bearer ${localStorage.getItem("access_token")}`,
		},
	});
};

const Detail = (id: string): Promise<Resp<Guest>> => {
	return request(`${HOST}/api/v1/guests/${id}`, {
		method: "GET",
		headers: {
			Authorization: `Bearer ${localStorage.getItem("access_token")}`,
		},
	});
};

const Create = (data: CreateGuestData): Promise<Resp<Guest>> => {
	return request(`${HOST}/api/v1/guests`, {
		method: "POST",
		data,
		headers: {
			Authorization: `Bearer ${localStorage.getItem("access_token")}`,
		},
	});
};

const Update = (id: string, data: CreateGuestData): Promise<Resp<Guest>> => {
	return request(`${HOST}/api/v1/guests/${id}`, {
		method: "PUT",
		data,
		headers: {
			Authorization: `Bearer ${localStorage.getItem("access_token")}`,
		},
	});
};

export default { list, Detail, Create, Update };
