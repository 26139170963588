import React from "react";
import Rooms from "src/components/user/room";

const Location = () => {
	return (
		<>
			<Rooms />
		</>
	);
};

export default Location;
