import { Resp } from "src/interface/common";
import { Device } from "src/interface/device";
import {
	Report,
	ReportUpdateData,
	GraphParams,
	OrderListParams,
} from "src/interface/order";
import { MonthlyOrders } from "src/interface/user/report";
import { PieGraph } from "src/interface/user/report";
import request from "../../utils/request";

const { REACT_APP_API_HOST: HOST } = process.env;

const Update = (id: string, data: ReportUpdateData): Promise<Resp<Report>> => {
	return request(`${HOST}/api/v1/reports/${id}`, {
		method: "PATCH",
		data,
		headers: {
			Authorization: `Bearer ${localStorage.getItem("access_token")}`,
		},
	});
};

const Detail = (params: GraphParams): Promise<Resp<PieGraph>> => {
	return request(`${HOST}/api/v1/reports/status`, {
		method: "GET",
		params,
		headers: {
			Authorization: `Bearer ${localStorage.getItem("access_token")}`,
		},
	});
};
const MonthlyList = async (
	params: OrderListParams
): Promise<
	Resp<{
		orders: MonthlyOrders[];
		total: number;
	}>
> => {
	return request(`${HOST}/api/v1/reports/monthlylist`, {
		method: "GET",
		params,
		headers: {
			Authorization: `Bearer ${localStorage.getItem("access_token")}`,
		},
	});
};
const deviceList = async (): Promise<Resp<Device[]>> => {
	return request(`${HOST}/api/v1/reports/devices`, {
		method: "GET",
		headers: {
			Authorization: `Bearer ${localStorage.getItem("access_token")}`,
		},
	});
};

async function GetReport(params: OrderListParams): Promise<any> {
	return request(`${HOST}/api/v1/reports/download`, {
		method: "GET",
		params,
		headers: {
			"Content-Type":
				"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
			Authorization: `Bearer ${localStorage.getItem("access_token")}`,
		},
		responseType: "blob",
	});
}
export default { Update, Detail, MonthlyList, deviceList, GetReport };
