import React from "react";
import { Form, Input, message } from "antd";
import Modal from "src/components/common/Modal";
import TextArea from "antd/lib/input/TextArea";
import { RoomForm, RoomTypeForm, RoomtypeProps } from "src/interface/user/room";
import roomtype from "src/services/user/roomtypes";
import { useEffect } from "react";

const CreateRoomType = ({
	modal,
	callBack,
	selected,
	selectRoomtype,
}: RoomtypeProps.Create) => {
	const [form] = Form.useForm();

	useEffect(() => {
		if (selected) {
			form.setFieldsValue({
				name: selected.name,
				description: selected.description,
				rent_per_hour: selected.rent_per_hour,
			});
		}
	}, [selected]);

	const handleSubmit = async (
		id: string | undefined,
		values: RoomTypeForm.Create
	) => {
		if (selected && id) {
			const res = await roomtype.Update(id, {
				...values,
			});

			if (res?.status && res?.data) {
				message.success("Room type updated successfully!");
				reset();
				callBack();
			}
		} else {
			const res = await roomtype.Create({
				...values,
			});
			if (res?.status && res?.data) {
				message.success("Room type created successfully!");
				reset();
				callBack();
			}
		}
	};

	const reset = () => {
		form.resetFields();
		selectRoomtype(undefined);
	};

	return (
		<>
			<Modal
				title={selected ? "Update Room Type" : "Create Room Type"}
				onCancel={reset}
				size={40}
				open={modal}
				footer={
					<div className="space-x-3 flex justify-end">
						<button className="btn btn--border py-1 px-6" onClick={reset}>
							Cancel
						</button>
						<button
							className="btn btn--primary py-1 px-6"
							form="create_location_form"
							type="submit"
						>
							{selected ? "Update" : "Create"}
						</button>
					</div>
				}
			>
				<Form
					className="space-y-4"
					form={form}
					layout="vertical"
					name="create_location_form"
					initialValues={{
						name: "",
						blocked: false,
					}}
					onFinish={(value) => handleSubmit(selected?.id, value)}
				>
					<Form.Item
						label="Name"
						name="name"
						rules={[{ required: true, message: "Missing location name" }]}
					>
						<Input className="input--primary p-2 border-0 text-white"></Input>
					</Form.Item>
					<Form.Item label="Description" name="description">
						<TextArea className="input--primary p-2 border-0 text-white" />
					</Form.Item>
					{/* <Form.Item label="Rent per hour" name="rent_per_hour">
						<Input
							className="input--primary p-2 border-0 text-white number"
							type="number"
						></Input>
					</Form.Item> */}
				</Form>
			</Modal>
		</>
	);
};

export default CreateRoomType;
