import React, { useState, useEffect } from "react";
import { Table } from "antd";

import { Category, CategoryProps } from "src/interface/user/category";

const CategoryList = ({ categories, onRow }: CategoryProps.List) => {
	const column = [
		{
			title: "Name",
			key: "name",
			align: "left" as const,

			render: ({ name }: Category) => (
				<p className="m-0 font-semibold">{name}</p>
			),
		},
		{
			title: "Description",
			key: "description",
			align: "center" as const,

			render: ({ description }: Category) => (
				<p className="m-0 font-medium">{description}</p>
			),
		},
		{
			title: "Stock Room",
			key: "stock_room",
			align: "center" as const,

			render: ({ stock_room }: Category) => (
				<p className="m-0 font-medium">{stock_room?.name}</p>
			),
		},
	];
	return (
		<div>
			<Table
				columns={column}
				dataSource={categories}
				rowClassName="cursor-pointer header-center"
				onRow={(row) => {
					return {
						onClick: () => onRow(row),
					};
				}}
			/>
		</div>
	);
};

export default CategoryList;
