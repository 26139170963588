import React from "react";
import Products from "src/components/user/product";

const Location = () => {
	return (
		<>
			<Products />
		</>
	);
};

export default Location;
