export const robotState: Record<number, string> = {
	0: "Idle",
	1: "Ready",
	2: "Working",
	3: "Mapping",
	4: "Path Recording",
	5: "Homing",
	6: "Going for charge",
	7: "Docking",
	8: "Charging",
	9: "Charging Ready",
	10: "Manual Charging",
	11: "Low Battery",
	12: "Emergency",
	13: "Unknown",
};
