import { Table } from "antd";
import { useEffect, useState } from "react";
import CreateGuest from "src/components/user/guests/CreateGuest";
import { Guest } from "src/interface/guest";

//Interfaces

//Services
import GuestService from "src/services/user/guest";

//Components

const Guests = () => {
	const [guests, setGuests] = useState<Guest[]>([]);
	const [selected, setSelected] = useState<Guest | undefined>();
	const [modal, setModal] = useState<boolean>(false);

	useEffect(() => {
		getGuests();
	}, []);

	const getGuests = async () => {
		const res = await GuestService.list();
		if (res.status && res.data) {
			setGuests(res.data);
		}
	};

	const toggleModel = () => {
		if (modal) {
			setSelected(undefined);
		}
		setModal(!modal);
	};

	const column = [
		{
			title: "Name",
			key: "name",
			align: "left" as const,
			render: ({ name }: Guest) => {
				return <strong>{name}</strong>;
			},
		},
		{
			title: "Booking ID",
			key: "booking",
			align: "center" as const,
			dataIndex: "booking",
		},
		{
			title: "Mobile",
			key: "mobile",
			align: "center" as const,

			render: ({ mobile, country_code }: Guest) => {
				return `+${country_code} ${mobile}`;
			},
		},
	];

	return (
		<div className="p-4 rounded-lg bg--secondary ">
			<CreateGuest
				selected={selected}
				onCreate={getGuests}
				modal={modal}
				toggle={toggleModel}
			/>
			<Table
				columns={column}
				dataSource={guests}
				rowClassName="cursor-pointer"
				// loading={loading}
				onRow={(row) => {
					return {
						onClick: () => {
							setSelected(row);
						},
					};
				}}
			/>
		</div>
	);
};

export default Guests;
