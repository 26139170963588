import React from "react";
import OrderList from "src/components/user/order/OrderList";
import DeviceList from "../components/user/device/DevicesList";

function Home() {
	const start = new Date();
	start.setUTCHours(0, 0, 0, 0);

	const end = new Date();
	end.setUTCHours(23, 59, 59, 999);
	return (
		<div id="dashboard">
			<h1 className={"mb-3 text-white dark:text-white font-bold"}>
				Robots Overview
			</h1>

			<div className="mb-10">
				<DeviceList />
			</div>
			<div className="bg--secondary rounded-md">
				<h1 className="text-xl font-normal text-white p-6 ">
					{"Today's Orders"}
				</h1>
				<OrderList start={start.toISOString()} end={end.toISOString()} />
			</div>
		</div>
	);
}

export default Home;
