import React, { ReactChild, useState } from "react";
import { CloseOutlined } from "@ant-design/icons";

const Chip = ({
	children,
	onDelete,
}: {
	children: ReactChild;
	onDelete: (value: string) => void;
}) => {
	const [hover, setHover] = useState<boolean>(false);

	const toggle = (state: boolean) => {
		setHover(state);
	};

	return (
		<span
			className="p-2 py-1 mb-1 rounded-full text-gray-400 border border-dashed border-gray-400 flex items-center"
			onMouseEnter={() => toggle(true)}
			onMouseLeave={() => toggle(false)}
		>
			{" "}
			{children}
			{hover && (
				<CloseOutlined
					size={20}
					className="ml-2"
					onClick={() => onDelete(children.toString())}
				/>
			)}
		</span>
	);
};

export default Chip;
