import React from "react";
import { Form, Input, message } from "antd";
import Modal from "src/components/common/Modal";
import { DeleteFilled } from "@ant-design/icons";
import room from "src/services/user/rooms";

import TextArea from "antd/lib/input/TextArea";
import { CategoryForm, CategoryProps } from "src/interface/user/category";
import categories from "src/services/user/categories";
import MultiSelect from "src/components/common/Select";
import { Room } from "src/interface/order";
import { useEffect, useState } from "react";

const CreateCategory = ({
	modal,
	callBack,
	selected,
	selectCategory,
}: CategoryProps.Create) => {
	const [form] = Form.useForm();
	const [rooms, setRooms] = useState<Room[]>([]);

	useEffect(() => {
		if (modal) {
			getRooms();
		}
	}, [modal]);

	useEffect(() => {
		if (selected) {
			form.setFieldsValue({
				name: selected.name,
				description: selected.description,
				stock_room_id: selected.stock_room_id,
			});
		}
	}, [selected]);

	const getRooms = async () => {
		const res = await room.List();
		if (res?.status && res?.data) {
			setRooms(res.data);
		}
	};

	const handleSubmit = async (
		id: string | undefined,
		values: CategoryForm.Create
	) => {
		if (selected && id) {
			const res = await categories.Update(id, {
				...values,
			});

			if (res?.status && res?.data) {
				message.success("Category updated successfully!");
				reset();
			}
		} else {
			const res = await categories.Create({
				...values,
			});
			if (res?.status && res?.data) {
				message.success("Category created successfully!");
				reset();
			}
		}
	};

	const reset = () => {
		form.resetFields();
		selectCategory(undefined);
		callBack();
	};

	const deleteCategory = async (id: string) => {
		const res = await categories.Delete(id);
		if (res?.status) {
			message.success("Category deleted successfully");
			reset();
		}
	};

	return (
		<>
			<Modal
				title={selected ? "Update Category" : "Create Category"}
				onCancel={reset}
				size={40}
				open={modal}
				footer={
					<div className="flex justify-between">
						{selected ? (
							<button
								className="text-white flex items-center space-x-2 font-normal py-1 px-4 text-md cursor-pointer transition-shadow  rounded-md  hover:shadow-lg border border-gray-600"
								onClick={() => deleteCategory(selected.id)}
							>
								<DeleteFilled className="text-red-500  " />
								<span>Delete</span>
							</button>
						) : (
							<div></div>
						)}
						<div className="space-x-3 ">
							<button className="btn btn--border py-1 px-6" onClick={reset}>
								Cancel
							</button>
							<button
								className="btn btn--primary py-1 px-6"
								form="create_product_form"
								type="submit"
							>
								{selected ? "Update" : "Create"}
							</button>
						</div>
					</div>
				}
			>
				<Form
					className="space-y-4"
					form={form}
					layout="vertical"
					name="create_product_form"
					initialValues={{
						name: "",
						blocked: false,
					}}
					onFinish={(value) => handleSubmit(selected?.id, value)}
				>
					<Form.Item
						label="Name"
						name="name"
						rules={[{ required: true, message: "Name is required" }]}
					>
						<Input className="input--primary p-2 border-0 text-white"></Input>
					</Form.Item>
					<Form.Item label="Description" name="description">
						<TextArea className="input--primary p-2 border-0 text-white" />
					</Form.Item>
					<Form.Item
						label="Stock Room"
						name="stock_room_id"
						rules={[{ required: true, message: "Stock is room is required" }]}
					>
						<MultiSelect
							options={rooms.map((r) => ({
								label: r.name,
								value: r.id,
							}))}
							onChange={(value) =>
								form.setFieldsValue({ stock_room_id: value })
							}
							selectedValue={form.getFieldValue("stock_room_id")}
						></MultiSelect>
					</Form.Item>
				</Form>
			</Modal>
		</>
	);
};

export default CreateCategory;
