import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";

const firebaseApp = initializeApp({
	apiKey: "AIzaSyDfbv7UFZJegzlu1f1khTCOFFhr2j7VrCA",
	authDomain: "hotel-robot-54a62.firebaseapp.com",
	projectId: "hotel-robot-54a62",
	storageBucket: "hotel-robot-54a62.appspot.com",
	messagingSenderId: "559030114488",
	appId: "1:559030114488:web:cf4059b342c676b1417c4d",
});

const messaging = getMessaging(firebaseApp);

function isTokenSentToServer(): boolean {
	return window.localStorage.getItem("sentToServer") === "1";
}

//disable eslint explicit any
/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
function setTokenSentToServer(sent: boolean): void {
	window.localStorage.setItem("sentToServer", sent ? "1" : "0");
}

export { messaging, isTokenSentToServer, setTokenSentToServer };
