import React from "react";
import moment from "moment";
import { AvgTime } from "src/interface/order";

interface props {
	data: AvgTime;
	total: number;
	flex?: boolean;
}

const Card = ({ data, total, flex }: props) => {
	return (
		<div
			className={`${
				flex
					? "flex-col col-span-2 justify-between"
					: "flex-row col-span-12 space-x-1 "
			}  flex `}
		>
			<div
				className={` ${
					flex
						? "card-piechart"
						: "w-1/2 border border-opacity-10 border-black mt-3 rounded"
				}`}
				style={{ padding: "34px" }}
			>
				<h1
					className={`${
						flex ? "text-white" : "text-black"
					} text-sm font-medium`}
				>
					Avg Delivery Time
				</h1>
				<div className="flex justify-center items-end m-4">
					<div className="text-4xl font-bold">
						{moment.utc(data.avgTime.avg * 1000).format("mm")}
					</div>
					<span className="text-lg">min</span>
					<div className="text-4xl font-bold ml-1">
						{moment.utc(data.avgTime.avg * 1000).format("ss")}
					</div>
					<span className="text-lg">sec</span>
				</div>
			</div>
			<div
				className={`${
					flex
						? "card-piechart"
						: "w-1/2 border border-opacity-10 border-black mt-3 rounded"
				}`}
				style={{ padding: "34px" }}
			>
				<h1
					className={`${
						flex ? "text-white" : "text-black"
					} text-sm font-semibold`}
				>
					Total Items Delivered
				</h1>
				<div className="flex justify-center items-end m-4">
					<div className="text-4xl font-bold ">{total}</div>
					<span className="text-lg ml-2">items</span>
				</div>
			</div>
		</div>
	);
};

export default Card;
