export default function ImageViewer(map, canvas) {
	const BinaryParser = function BinaryParser(data, bytes) {
		this.data = data;
		this.bytes = bytes;
		this.pointer = 0;
	};

	BinaryParser.prototype.getNextSample = function getNextSample() {
		if (this.pointer >= this.data.length) return false;

		let val = 0;
		for (let i = 0; i < this.bytes; i += 1) {
			val = val * 255 + this.data[(this.pointer += 1)];
		}
		return val;
	};

	const PGMFormatter = function PGMFormatter(width, height, maxVal) {
		this.width = width;
		this.height = height;
		this.maxVal = maxVal;
	};

	PGMFormatter.prototype.getCanvas = function getCanvas(parser) {
		const canvas = document.createElement("canvas");
		const ctx = canvas.getContext("2d");
		// Add behind elements.
		ctx.globalCompositeOperation = "destination-over";
		// Now draw!
		// ctx.fillStyle = "blue";
		// ctx.fillRect(0, 0, canvas.width, canvas.height);

		ctx.width = this.width;
		canvas.width = this.width;

		ctx.height = this.height;
		canvas.height = this.height;

		const img = ctx.getImageData(0, 0, this.width, this.height);
		let imgD;

		for (let row = this.height; row > 0; row -= 1) {
			for (let col = 0; col < this.width; col += 1) {
				const factor = 255 / this.maxVal;
				const r = factor * parser.getNextSample();
				const pos = (row * this.width + col) * 4;
				img.data[pos] = r > 50 ? r + 255 : r < 50 && r > -1 ? 50 : r - 100;
				img.data[pos + 1] = r > 50 ? r + 255 : r < 50 && r > -1 ? 50 : r - 100;
				img.data[pos + 2] = r > 50 ? r + 255 : r < 50 && r > -1 ? 50 : r - 100;
				img.data[pos + 3] = 255;
			}
		}
		ctx.putImageData(img, 0, 0);
		return canvas;
	};
	const width = map.info.width; //map.info.width;
	const height = map.info.height; //map.info.height;
	const imageData = map.data;
	const maxVal = 100;
	const bytes = maxVal < 256 ? 1 : 2;

	this.parser = new BinaryParser(imageData, bytes);
	this.formatter = new PGMFormatter(width, height, maxVal);

	ImageViewer.prototype.getCanvas = function getCanvas() {
		return this.formatter.getCanvas(this.parser);
	};
}
