import { useEffect, useState } from "react";
import { Battery, Telemetry } from "src/interface/device";
import ReactECharts from "echarts-for-react";
import moment, { utc } from "moment";

import DeviceService from "src/services/user/devices";
import { DatePicker } from "antd";

const BatteryStatus = ({ id }: { id: string }) => {
	const [time, setTime] = useState<Telemetry<Battery>>([]);

	useEffect(() => {
		handleSubmit(moment().toISOString());
	}, []);

	const handleSubmit = async (date: string) => {
		const momentDate = moment(date);
		const start = momentDate.startOf("day").toISOString();
		const stop = momentDate.endOf("day").toISOString();
		const res = await DeviceService.GetTelemetry<Battery>(id, {
			start,
			stop,
			measurement: "agent",
			field: "battery",
			window: "1m",
		});
		if (res.status) {
			setTime(res.data || []);
		}
	};

	const limit = Array.isArray(time) ? (time.length / 24) * 3 : 0;
	const options = {
		tooltip: {
			trigger: "axis",
			formatter: "{a1}<br/>{b1}: {c1}%",
			textStyle: {
				color: "black",
				fontSize: 14,
			},
			axisPointer: {
				type: "shadow",
			},
		},
		maintainAspectRatio: false,
		responsive: true,

		xAxis: [
			{
				type: "category",
				data: time.map((i) =>
					moment(utc(i.time, "YYYY-MM-DD hh:mm").local())
						.subtract(5, "m")
						.format("hh:mm")
				),
				axisLabel: {
					interval: limit,
				},
			},
		],
		yAxis: [
			{
				type: "value",
				name: "",
				min: 0,
				max: 100,
				interval: 50,
				splitLine: {
					lineStyle: {
						color: "rgba(255,255,255,0.1)",
					},
				},
				// splitLine: true,
			},
			{
				show: false,
			},
		],

		series: [
			{
				name: "Status",
				type: "line",
				symbol: "none",
				areaStyle: {
					color: "rgba(43, 168, 75, 0.2)",
				},
				lineStyle: {
					width: 0,
				},
				yAxisIndex: 1,
				data: time?.map((i) => {
					if (i.value && i.value.charging) {
						return {
							value: 100,
						};
					} else {
						return {
							value: null,
						};
					}
				}),
			},
			{
				name: "Percentage",
				type: "bar",
				itemStyle: {
					borderRadius: [3, 3, 0, 0],
				},
				data: time?.map((i) => {
					if (i.value) {
						if (i.value.value <= 25) {
							return {
								value: i.value.value?.toFixed(2),
								itemStyle: {
									color: "#aa352f",
								},
							};
						} else {
							return {
								value: i.value.value?.toFixed(2),
								itemStyle: {
									color: "#2ba84b",
								},
							};
						}
					}
				}),
			},
		],
	};
	return (
		<div className="rounded-card font-semibold p-8">
			<div className="flex justify-between">
				<h1 className="text-xl text-white font-light">Battery Statistics</h1>
				<DatePicker
					onChange={(value) => handleSubmit(moment(value).toISOString())}
					defaultValue={moment()}
					clearIcon
					className="date px-5 text-lg"
				/>
			</div>
			<ReactECharts
				option={options}
				opts={{ renderer: "svg" }}
				style={{ height: "400px" }}
			/>
		</div>
	);
};

export default BatteryStatus;
