import { Location, LocationForm } from "src/interface/admin/location";
import { Resp } from "src/interface/common";
import request from "src/utils/request";

const { REACT_APP_API_HOST: HOST } = process.env;

const List = (): Promise<Resp<Location[]>> => {
	return request(`${HOST}/api/v1/locations`, {
		method: "GET",
		headers: {
			Authorization: `Bearer ${localStorage.getItem("access_token")}`,
		},
	});
};

const Create = (data: LocationForm.Create): Promise<Resp<Location>> => {
	return request(`${HOST}/api/v1/locations`, {
		method: "POST",
		data,
		headers: {
			Authorization: `Bearer ${localStorage.getItem("access_token")}`,
		},
	});
};

const AssignUser = (
	locId: string,
	users: string[]
): Promise<Resp<Location>> => {
	return request(`${HOST}/api/v1/locations/${locId}/users`, {
		method: "PATCH",
		data: {
			users,
		},
		headers: {
			Authorization: `Bearer ${localStorage.getItem("access_token")}`,
		},
	});
};

const Update = (
	id: string,
	data: LocationForm.Create
): Promise<Resp<Location>> => {
	return request(`${HOST}/api/v1/locations/${id}`, {
		method: "PATCH",
		data,
		headers: {
			Authorization: `Bearer ${localStorage.getItem("access_token")}`,
		},
	});
};

export default { List, Create, AssignUser, Update };
