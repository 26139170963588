import React, { ReactNode, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ForgotPassword from "../components/ForgotPassword";
import Login from "src/components/auth/Login";
import LoginBg from "src/assets/robot.png";
import PatternsBg from "src/assets/bg.svg";
import { motion, AnimatePresence } from "framer-motion";
import { useAuth0 } from "@auth0/auth0-react";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

export type LoginProps = {
	children?: ReactNode;
};

const LoginPage: React.FC<LoginProps> = () => {
	const { isAuthenticated, loginWithRedirect } = useAuth0();
	const useQuery = () => new URLSearchParams(useLocation().search);
	const [FPass, setFPass] = useState<boolean>(false);
	const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

	useEffect(() => {
		loginWithRedirect();
	}, []);

	const toggleFPass = () => {
		setFPass(!FPass);
	};

	return (
		<div className="h-screen w-screen flex items-center justify-center login">
			<Spin
				indicator={antIcon}
				size="large"
				style={{ color: "white", fontSize: "48px" }}
			/>
		</div>
	);
};

export default LoginPage;
