import React, { useState } from "react";
import { Col, Form, Input, message, Row } from "antd";

import Modal from "src/components/common/Modal";
import { Location } from "src/interface/admin/location";
import room from "src/services/user/rooms";
import location from "src/services/user/locations";
import roomType from "src/services/user/roomtypes";

import TextArea from "antd/lib/input/TextArea";
import { RoomForm, RoomProps, RoomType } from "src/interface/user/room";
import MultiSelect from "src/components/common/Select";
import { useEffect } from "react";
import Select from "../../common/Select";
import countries from "src/utils/countries.json";
import Chip from "src/components/common/Chip";

const CreateLocation = ({
	toggleModal,
	modal,
	callBack,
	selected,
	selectDevice,
}: RoomProps.Create) => {
	const [form] = Form.useForm();
	const [roomtypes, setRoomTypes] = useState<RoomType[]>([]);
	const [locations, setLocations] = useState<Location[]>([]);
	const [countryCode, setCountryCode] = useState<undefined | string>("");
	const [integrationKeys, setIntegrationKeys] = useState<string[]>([]);
	const [integrationKey, setIntegrationKey] = useState<string>();

	useEffect(() => {
		if (modal) {
			getLocations();
			getRoomTypes();
		}
	}, [modal]);

	useEffect(() => {
		if (selected) {
			form.setFieldsValue({
				name: selected.name,
				description: selected.description,
				room_type: selected.room_type.id,
				location: selected.location.id,
				room_number: selected.room_number,
				floor_number: selected.floor_number,
				is_occupied: selected.is_occupied,
				ros_key: selected.ros_key,
				mobile: selected.mobile,
				country_code: selected.country_code,
				block: selected.block,
			});
			setIntegrationKeys(selected.integration_keys);
			setCountryCode(String(selected?.country_code ?? ""));
		}
	}, [selected]);

	const getLocations = async () => {
		const res = await location.List();
		if (res?.status && res?.data) {
			setLocations(res.data);
		}
	};

	const getRoomTypes = async () => {
		const res = await roomType.List();
		if (res?.status && res?.data) {
			setRoomTypes(res.data);
		}
	};

	const handleSubmit = async (
		id: string | undefined,
		values: RoomForm.Create
	) => {
		if (selected && id) {
			const res = await room.Update(id, {
				...values,
				mobile: values.mobile || null,
				country_code: countryCode || null,
				integration_keys: integrationKeys,
			});

			if (res?.status && res?.data) {
				message.success("Room updated successfully!");
				toggleModal();
				reset();
				callBack();
			}
		} else {
			const res = await room.Create({
				...values,
				mobile: values.mobile || null,
				country_code: countryCode || null,
				integration_keys: integrationKeys,
			});
			if (res?.status && res?.data) {
				message.success("Room created successfully!");
				toggleModal();
				reset();
				callBack();
			}
		}
		setCountryCode("");
	};

	const reset = () => {
		form.resetFields();
		selectDevice(undefined);
		setIntegrationKeys([]);
		setIntegrationKey(undefined);
	};

	return (
		<>
			<Modal
				title={selected ? "Update Room" : "Create Room"}
				onCancel={reset}
				size={50}
				open={modal}
				footer={
					<div className="space-x-3 flex justify-end">
						<button className="btn btn--border py-1 px-6" onClick={reset}>
							Cancel
						</button>
						<button
							className="btn btn--primary py-1 px-6"
							form="create_location_form"
							type="submit"
						>
							{selected ? "Update" : "Create"}
						</button>
					</div>
				}
			>
				<Form
					className="space-y-4"
					form={form}
					layout="vertical"
					name="create_location_form"
					initialValues={{
						name: "",
						blocked: false,
					}}
					onFinish={(value) => handleSubmit(selected?.id, value)}
				>
					<Form.Item
						label="Name"
						name="name"
						rules={[{ required: true, message: "Room name is required" }]}
					>
						<Input className="input--primary p-2 border-0 text-white"></Input>
					</Form.Item>
					<Form.Item label="Description" name="description">
						<TextArea className="input--primary p-2 border-0 text-white" />
					</Form.Item>
					<Row gutter={[20, 0]}>
						<Col span={12}>
							<Form.Item
								className="col-span-6"
								label="Room Type"
								name="room_type"
								rules={[{ required: true, message: "Room Type is required" }]}
							>
								<MultiSelect
									options={roomtypes.map((r) => ({
										label: r.name,
										value: r.id,
									}))}
									onChange={(value) =>
										form.setFieldsValue({ room_type: value })
									}
									selectedValue={form.getFieldValue("room_type")}
								></MultiSelect>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item
								className="col-span-6"
								label="Location"
								name="location"
								rules={[{ required: true, message: "Location is required" }]}
							>
								<MultiSelect
									options={locations.map((l) => ({
										label: l.name,
										value: l.id,
									}))}
									onChange={(value) => form.setFieldsValue({ location: value })}
									selectedValue={form.getFieldValue("location")}
								></MultiSelect>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label="Room Number" name="room_number">
								<Input
									className="input--primary p-2 border-0 text-white number"
									type="number"
								/>
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item
								label="Floor Number"
								name="floor_number"
								rules={[
									{ required: true, message: "Floor number is Required" },
								]}
							>
								<Input
									className="input--primary p-2 border-0 text-white number"
									type="number"
								/>
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item label="Block" name="block">
								<Input
									className="input--primary p-2 border-0 text-white number"
									type="text"
								/>
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item
								label="Ros Key"
								name="ros_key"
								rules={[{ required: true, message: "Ros key is required" }]}
							>
								<Input className="input--primary p-2 border-0 text-white" />
							</Form.Item>
						</Col>
						<Col span={18}>
							<Row gutter={[12, 0]}>
								<Col span={8}>
									<Form.Item name="country_code" label="Country">
										<Select
											onChange={(value) => {
												console.log(value);
												if (!Array.isArray(value)) {
													form.setFieldsValue({ country_code: value });
													setCountryCode(value);
												}
											}}
											placeholder="Country Code"
											multiple={false}
											options={countries.map((c) => {
												return {
													label: `${c.name} (${c.dial_code})`,
													value: c.dial_code,
												};
											})}
											selectedValue={countryCode}
										/>
									</Form.Item>
								</Col>
								<Col span={16}>
									<Form.Item name="mobile" label="Mobile Number">
										<Input
											type="number"
											placeholder="Phone Number (65 xxx xxxx)"
											className="input--primary p-2 border-0 text-white number w-full"
										/>
									</Form.Item>
								</Col>
							</Row>
						</Col>
						<Col span={24}>
							<Form.Item label="Integration Keys">
								<div className="flex items-center space-x-3">
									<Input
										onPressEnter={() => {
											integrationKey &&
												setIntegrationKeys([
													...integrationKeys,
													integrationKey,
												]);
											setIntegrationKey(undefined);
										}}
										className="input--primary p-2 border-0 text-white"
										value={integrationKey}
										onChange={(e) => setIntegrationKey(e.target.value)}
									></Input>
									<button
										className="btn btn--border py-2 px-6"
										type="button"
										onClick={() => {
											integrationKey &&
												setIntegrationKeys([
													...integrationKeys,
													integrationKey,
												]);
											setIntegrationKey(undefined);
										}}
									>
										Add
									</button>
								</div>
								{integrationKeys?.length > 0 && (
									<div className="m-2 space-x-1 flex flex-wrap">
										{integrationKeys.map((kw, index) => (
											<Chip
												key={index}
												onDelete={(value) =>
													setIntegrationKeys(
														integrationKeys.filter((k) => k !== value)
													)
												}
											>
												{kw}
											</Chip>
										))}
									</div>
								)}
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</Modal>
		</>
	);
};

export default CreateLocation;
