import { Resp } from "src/interface/common";
import {
	AvgTime,
	CreateOrderData,
	Order,
	GraphParams,
	OrderListParams,
	UpdateOrderData,
} from "src/interface/order";
import request from "../../utils/request";

const { REACT_APP_API_HOST: HOST } = process.env;

const List = async (
	accessToken: string,
	params: OrderListParams
): Promise<
	Resp<{
		orders: Order[];
		total: number;
	}>
> => {
	return request(`${HOST}/api/v1/orders`, {
		method: "GET",
		params,
		headers: {
			Authorization: `Bearer ${accessToken}`,
		},
	});
};

const Detail = (
	id: string,
	params: { device: boolean }
): Promise<Resp<Order>> => {
	return request(`${HOST}/api/v1/orders/${id}`, {
		method: "GET",
		headers: {
			Authorization: `Bearer ${localStorage.getItem("access_token")}`,
		},
		params,
	});
};

const Create = (data: CreateOrderData): Promise<Resp<Order>> => {
	return request(`${HOST}/api/v1/orders`, {
		method: "POST",
		headers: {
			Authorization: `Bearer ${localStorage.getItem("access_token")}`,
		},
		data,
	});
};

const Cancel = (id: string): Promise<Resp<Order>> => {
	return request(`${HOST}/api/v1/orders/${id}/cancel`, {
		method: "POST",
		headers: {
			Authorization: `Bearer ${localStorage.getItem("access_token")}`,
		},
	});
};

async function GetReport(id: string): Promise<any> {
	// const token = localStorage.getItem('access-token')
	return request(`${HOST}/api/v1/orders/${id}/report`, {
		method: "GET",
		headers: {
			"Content-Type":
				"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
			Authorization: `Bearer ${localStorage.getItem("access_token")}`,
		},
		responseType: "blob",
	});
}

const avgTime = (params: GraphParams): Promise<Resp<AvgTime>> => {
	return request(`${HOST}/api/v1/orders/avgtime`, {
		method: "GET",
		params,
		headers: {
			Authorization: `Bearer ${localStorage.getItem("access_token")}`,
		},
	});
};

const Update = (id: string, data: UpdateOrderData): Promise<Resp<Order>> => {
	return request(`${HOST}/api/v1/orders/${id}`, {
		method: "PUT",
		headers: {
			Authorization: `Bearer ${localStorage.getItem("access_token")}`,
		},
		data,
	});
};

export default {
	/*  list, Detail, */ Create,
	List,
	Detail,
	Cancel,
	GetReport,
	avgTime,
	Update,
};
