import React, { useState, useEffect } from "react";
import { Location } from "src/interface/admin/location";
import location from "src/services/user/locations";
import CreateLocation from "./CreateLocation";
import LocationsList from "./LocationsList";
import UpdateLocation from "./UpdateLocation";

import { PlusOutlined } from "@ant-design/icons";
import { WithPermission } from "src/utils/utils";
import { useAuth0 } from "@auth0/auth0-react";

const Locations = () => {
	const [locations, setLocations] = useState<Location[]>([]);
	const [selected, setSelected] = useState<Location>();
	const [modal, setModal] = useState<boolean>(false);
	const { getAccessTokenSilently } = useAuth0();

	const toggleModal = () => {
		setModal(!modal);
		if (!modal) {
			setSelected(undefined);
		}
	};

	useEffect(() => {
		getLocations();
	}, []);

	const getLocations = async () => {
		const res = await location.List();
		if (res?.status && res?.data) {
			setLocations(res.data);
		}
	};

	const selectDevice = (location: Location) => {
		toggleModal();
		setSelected(location);
	};

	return (
		<div className="p-4 rounded-md bg--secondary">
			<div className="flex justify-end mb-4">
				<WithPermission permission="location.create">
					<button
						className="py-2 px-6 text-sm leading-5 font-medium text-white rounded-md  flex items-center btn btn--border "
						onClick={toggleModal}
					>
						<PlusOutlined className="mr-1 " /> Create Location
					</button>
				</WithPermission>
			</div>

			<CreateLocation
				modal={modal}
				toggleModal={toggleModal}
				callBack={getLocations}
				selected={selected}
			/>
			<WithPermission permission="location.list">
				<LocationsList locations={locations} onRow={selectDevice} />
			</WithPermission>
			{/* {selected && (
				<WithPermission permission="location.update">
					<UpdateLocation
						toggleModal={toggleModal}
						selected={selected}
						modal={modal}
						callBack={getLocations}
					/>
				</WithPermission>
			)} */}
		</div>
	);
};

export default Locations;
