import { ReactNode, useEffect, useState } from "react";
import { Layout, Menu } from "antd";
import { PoweroffOutlined, UserOutlined } from "@ant-design/icons";
import { Link, useLocation, useHistory } from "react-router-dom";
import _ from "lodash";
import { X } from "react-feather";

import { MenuList } from "src/user/App";
import { decodePayload } from "../utils/auth";

import logo from "../assets/logo.png";

import "../styles/misc.css";
import Avatar from "antd/lib/avatar/avatar";
import Dropdown, { menu } from "./common/Dropdown";
import { hasPermission, subString, WithPermission } from "../utils/utils";
import PatternsBg from "src/assets/bg.svg";
import Bell from "src/assets/icons/Bell.svg";
import Modal from "./common/Modal";
import Notification from "./common/Notification";
import { useAuth0 } from "@auth0/auth0-react";

const { Header, Content, Sider } = Layout;

interface Props {
	needSider: boolean | true;
	children: ReactNode;
	menuList: MenuList[];
	notifications: menu[];
	clearNotification: () => void;
	token?: string | null;
}

function SiderComponent(props: Props) {
	const { logout, isAuthenticated, user, isLoading } = useAuth0();
	// const isAuthenticated = true;
	const payload = decodePayload();
	const history = useHistory();
	const location = useLocation();
	const [selectedKey, setSelectedKey] = useState(
		props.menuList.find((_item) => location.pathname.startsWith(_item.route))
			?.key
	);
	const [modal, setModal] = useState<boolean>(false);

	useEffect(() => {
		setSelectedKey(
			props.menuList.find((_item) => location.pathname.startsWith(_item.route))
				?.key
		);
	}, [location]);

	const logOut = () => {
		localStorage.clear();
		logout({
			returnTo: window.location.origin,
		});
	};

	const toggleModal = () => {
		setModal(!modal);
	};

	const USER_MENU: menu[] = [
		{
			title: "Profile",
			path: "/profile",
			icon: <UserOutlined />,
		},
		{
			title: "Logout",
			onClick: logOut,
			color: "red",
			icon: <PoweroffOutlined />,
		},
	];

	return (
		<Layout>
			{isAuthenticated && (
				<Sider width="200" theme="dark" trigger={null} breakpoint="sm">
					<img
						className="h-12 mt-4 w-full object-scale-down p-2"
						src={logo}
						alt={"KeyVault"}
					/>

					<Menu
						className={"mt-6"}
						theme="light"
						mode="inline"
						defaultSelectedKeys={[selectedKey ? selectedKey : "Dashboard"]}
					>
						{props.menuList.map(
							(value) =>
								hasPermission(value.permission) && (
									<Menu.Item
										className={"flex items-center place-content-left "}
										key={value.key}
									>
										<Link to={value.route}>
											{_.startCase(_.camelCase(value.key))}
										</Link>
									</Menu.Item>
								)
						)}
					</Menu>
				</Sider>
			)}
			<Layout
				className="bg--primary h-screen overflow-y-auto"
				style={{
					backgroundImage: `url(${PatternsBg})`,
					backgroundSize: "cover",
					backgroundPosition: "bottom",
					zIndex: 0,
				}}
			>
				{isAuthenticated && (
					<Header
						className="bg--primary px-8 flex flex-col my-4 items-end justify-center"
						style={{ height: "100px" }}
					>
						<div className="space-x-7">
							<WithPermission permission="order.create">
								<button
									className="btn btn--primary leading-none w-80"
									onClick={() => history.push(`/orders/create`)}
								>
									Call for Robot
								</button>
							</WithPermission>
							<div className="float-right flex items-center space-x-4">
								<Notification
									menu={props.notifications}
									width={400}
									footer={
										props.notifications.length ? (
											<div
												className="flex items-center font-semibold text-white"
												onClick={props.clearNotification}
											>
												Clear <X size={18} />
											</div>
										) : undefined
									}
								>
									<div className="relative flex items-center">
										<img src={Bell} alt="" />
										{props.notifications.length && (
											<span className="flex h-2 w-2 absolute top-0 right-0">
												<span className="animate-ping absolute inline-flex  h-2 w-2 rounded-full bg-white opacity-75"></span>
												<span className="relative inline-flex rounded-full  h-2 w-2 bg-white"></span>
											</span>
										)}
									</div>
								</Notification>
								<Dropdown
									menu={USER_MENU}
									title={
										user ? `Hi ${subString(user?.nickname, 9)}` : "Hi User!"
									}
									width={180}
									// height={150}
								>
									<Avatar size="large" className="btn--primary capitalize">
										{user?.nickname?.substr(0, 1).toUpperCase()}
									</Avatar>
								</Dropdown>
							</div>
						</div>

						<div
							className="ml-4 w-full pb-3"
							style={{ borderBottom: "0.1px solid #3e3e3e" }}
						>
							<p className={"text-2xl text-white dark:text-white font-medium"}>
								{selectedKey}
							</p>
						</div>
					</Header>
				)}
				<Content className={`${isAuthenticated ? "p-8" : "p-0"}`}>
					{props.children}
				</Content>
			</Layout>
			<Modal
				title="Logout"
				onCancel={toggleModal}
				size={26}
				open={modal}
				footer={
					<div className="space-x-3 flex justify-end">
						<button className="btn btn--border py-1 px-6" onClick={toggleModal}>
							Cancel
						</button>
						<button className="btn btn--primary py-1 px-6" onClick={logOut}>
							Logout
						</button>
					</div>
				}
			>
				<p className="text-white font-normal mb-10">
					Are you sure you want to logout?
				</p>
			</Modal>
		</Layout>
	);
}

export default SiderComponent;
