import { Location, LocationForm } from "src/interface/admin/location";
import { Resp } from "src/interface/common";
import { Room } from "src/interface/order";
import { RoomForm } from "src/interface/user/room";
import request from "src/utils/request";

const { REACT_APP_API_HOST: HOST } = process.env;

const List = (): Promise<Resp<Room[]>> => {
	return request(`${HOST}/api/v1/rooms`, {
		method: "GET",
		headers: {
			Authorization: `Bearer ${localStorage.getItem("access_token")}`,
		},
	});
};

const Create = (data: RoomForm.Create): Promise<Resp<Room>> => {
	return request(`${HOST}/api/v1/rooms`, {
		method: "POST",
		headers: {
			Authorization: `Bearer ${localStorage.getItem("access_token")}`,
		},
		data,
	});
};

const Update = (id: string, data: RoomForm.Create): Promise<Resp<Room>> => {
	return request(`${HOST}/api/v1/rooms/${id}`, {
		method: "PATCH",
		headers: {
			Authorization: `Bearer ${localStorage.getItem("access_token")}`,
		},
		data,
	});
};

const Delete = (id: string): Promise<Resp<Room>> => {
	return request(`${HOST}/api/v1/rooms/${id}`, {
		method: "DELETE",
		headers: {
			Authorization: `Bearer ${localStorage.getItem("access_token")}`,
		},
	});
};

export default { List, Create, Delete, Update };
