import React from "react";
import { Table } from "antd";
import { LocationProps, Location } from "../../../interface/admin/location";
import { RoomProps } from "src/interface/user/room";
import { Room } from "src/interface/order";

const LocationsList = ({ rooms, onRow }: RoomProps.List) => {
	const column = [
		{
			title: "Name",
			key: "name",
			align: "left" as const,

			render: ({ name }: Room) => <p className="m-0 font-semibold">{name}</p>,
		},
		{
			title: "Description",
			key: "description",
			align: "center" as const,

			render: ({ description }: Room) => (
				<p className="m-0 font-medium">{description}</p>
			),
		},
		{
			title: "Room Type",
			key: "room_type",
			align: "center" as const,

			render: ({ room_type }: Room) => (
				<p className="m-0 font-medium">{room_type.name}</p>
			),
		},
		{
			title: "Location",
			key: "location",
			align: "center" as const,

			render: ({ location }: Room) => (
				<p className="m-0 font-medium">{location.name}</p>
			),
		},
		{
			title: "Room Number",
			key: "room_number",
			align: "center" as const,

			render: ({ room_number }: Room) => (
				<p className="m-0 font-medium">{room_number}</p>
			),
		},
	];
	return (
		<div>
			<Table
				columns={column}
				dataSource={rooms}
				rowClassName="cursor-pointer header-center"
				onRow={(row) => {
					return {
						onClick: () => onRow(row),
					};
				}}
			/>
		</div>
	);
};

LocationsList.displayName = "LocationsList";

export default LocationsList;
