import { Col, Form, Input, message, Row } from "antd";
import { useState } from "react";
import {
	PlusOutlined,
	MinusOutlined,
	LoadingOutlined,
} from "@ant-design/icons";
import { Trash } from "react-feather";

import ProductList from "./ProductList";
import { Item, Product } from "src/interface/product";
import { OrderFormInput } from "src/interface/order";
import guest from "src/services/user/guest";
import order from "src/services/user/order";
import Arrow from "../../../assets/Arrow.svg";
import { useHistory } from "react-router-dom";
import Search from "../../common/InputSelect";
import { Guest } from "src/interface/guest";
import Select from "src/components/common/Select";

import countries from "src/utils/countries.json";
import { isArray } from "lodash";

interface UserValue {
	label: string;
	value: string;
}

const CreateOrder = () => {
	const history = useHistory();
	const [form] = Form.useForm<OrderFormInput>();
	const [prodListView, setProdLisView] = useState(false);
	const [items, setItems] = useState<Item[]>([]);
	const [countryCode, setCountryCode] = useState("");
	const [loading, setLoading] = useState(false);

	const toggle = () => {
		setProdLisView(!prodListView);
	};

	const getGuest = async (booking_id: string) => {
		const res = await guest.list({
			booking_id,
		});
		if (res?.status && res?.data) {
			return res.data;
		} else return [];
	};

	const manageItem = (product: Product, process: "ADD" | "SUB" | "REMOVE") => {
		const prods: Item[] = JSON.parse(JSON.stringify(items));
		const index = prods.findIndex((i) => i.item.id === product.id);
		if (process === "ADD") {
			if (index > -1) {
				prods[index].quantity += 1;
			} else {
				prods.push({
					item: product,
					quantity: 1,
				});
			}
		} else if (process === "SUB") {
			if (index > -1) {
				prods[index].quantity -= 1;
				if (prods[index].quantity === 0) {
					prods.splice(index, 1);
				}
			}
		} else if (process === "REMOVE") {
			setItems(items.filter((i) => i.item.id !== product.id));
			return;
		}
		setItems(prods);
	};

	async function fetchUserList(booking_id: string): Promise<Guest[]> {
		const guests = await getGuest(booking_id);
		return guests;
	}

	const handleSubmit = async (data: OrderFormInput) => {
		if (!items.length) {
			return message.error("Please select atleast one product to continue!");
		}
		setLoading(true);
		let guestRes = (await getGuest(data.booking))[0];
		if (!guestRes) {
			const res = await guest.Create({
				booking: data.booking,
				name: data.name,
				mobile: String(data.mobile),
				country_code: parseInt(data.country_code),
			});
			if (res?.status && res?.data) {
				guestRes = res.data;
			}
		}
		const orderItems = items.map((i) => ({
			product_id: i.item.id,
			quantity: i.quantity,
		}));
		if (guestRes) {
			const newOrder = await order.Create({
				guest: guestRes.id,
				room: data.room,
				cart: orderItems,
			});
			if (newOrder?.status && newOrder?.data) {
				message.success("Order created successfully!");
				history.push(`/orders/${newOrder.data.id}`);
			}
		}
		setLoading(false);
	};

	return (
		<div className="w-4/5 mx-auto h-full">
			{!prodListView ? (
				<div className="flex flex-col h-full space-y-8">
					<div className="mt-8 flex justify-between">
						<img
							src={Arrow}
							alt=""
							className="cursor-pointer"
							onClick={() => history.goBack()}
						/>

						<h1 className="font-bold text-xl text-white text-center mr-6">
							Enter the Customer Details
						</h1>
						<div></div>
					</div>
					<div className="flex-1">
						<Form form={form} onFinish={handleSubmit}>
							<Row gutter={[20, 7]}>
								<Col span={12}>
									<Form.Item
										name="name"
										rules={[
											{
												required: true,
												message: "Name is required!",
											},
										]}
									>
										<Input
											autoComplete="off"
											type="text"
											placeholder="Name"
											className="input--primary p-2 border-0 text-white"
										/>
									</Form.Item>
								</Col>
								<Col span={12}>
									<Row gutter={[12, 0]}>
										<Col span={8}>
											<Form.Item
												name="country_code"
												rules={[
													{
														required: true,
														message: "Country code is required!",
													},
												]}
											>
												<Select
													onChange={(value) => {
														if (!isArray(value)) {
															form.setFieldsValue({ country_code: value });
															setCountryCode(value);
														}
													}}
													placeholder="Country Code"
													multiple={false}
													options={countries.map((c) => {
														return {
															label: `${c.name} (${c.dial_code})`,
															value: c.dial_code,
														};
													})}
													selectedValue={countryCode}
												/>
											</Form.Item>
										</Col>
										<Col span={16}>
											<Form.Item
												name="mobile"
												rules={[
													{
														required: true,
														message: "Phone number is required!",
													},
												]}
											>
												<Input
													autoComplete="off"
													type="number"
													placeholder="Phone Number (65 xxx xxxx)"
													className="input--primary p-2 border-0 text-white number w-full"
												/>
											</Form.Item>
										</Col>
									</Row>
								</Col>

								<Col span={12}>
									<Form.Item
										name="booking"
										rules={[
											{
												required: true,
												message: "Booking ID is required!",
											},
											{
												pattern: new RegExp(/^[0-9A-Z]+$/),
												message: "Booking ID should be A-Z,0-9",
											},
										]}
										className="w-full"
									>
										<Search
											fetchOptions={fetchUserList}
											placeholder="Booking ID"
											value={form.getFieldValue("booking")}
											onChange={(value) => {
												if (typeof value === "string") {
													form.setFieldsValue({ booking: value });
												} else {
													form.setFieldsValue({
														name: value.name,
														mobile: value.mobile,
														booking: value.booking,
														country_code: String(value.country_code),
													});
													setCountryCode(String(value.country_code));
												}
											}}
										/>
									</Form.Item>
								</Col>
								<Col span={12}>
									<Form.Item
										name="room"
										rules={[
											{
												required: true,
												message: "Room no is required!",
											},
										]}
									>
										<Input
											autoComplete="off"
											type="number"
											placeholder="Room No"
											className="input--primary p-2 border-0 text-white number"
										/>
									</Form.Item>
								</Col>
								<Col span={24} onClick={toggle}>
									<Form.Item name="products">
										<Input
											autoComplete="off"
											type="text"
											placeholder={
												items.length > 0 ? "Add more items" : "Add your items"
											}
											className="input--primary p-2 border-0 text-white cursor-pointer"
											// suffix={<PlusCircleOutlined />}
										/>
									</Form.Item>
								</Col>
								{items.length > 0 && (
									<Col span={24} className="">
										<div className="input--primary px-6 w-full max-h-90 overflow-y-auto">
											{items.map((i, index) => (
												<div
													key={index}
													className={`py-3 flex justify-between items-center w-full ${
														index !== items.length - 1 &&
														"border-b border-gray-700"
													}`}
												>
													<p className="font-semibold pl-6">{i.item.name}</p>
													<div className="flex items-center space-x-20 py-2 px-6 ">
														<div className="flex items-center space-x-8">
															<button type="button">
																<MinusOutlined
																	className="flex border-red-500 border rounded-full p-2"
																	onClick={() => manageItem(i.item, "SUB")}
																/>
															</button>
															<span>
																{items.find(
																	(item) => item.item.id === i.item.id
																)?.quantity ?? 0}
															</span>
															<button
																type="button"
																onClick={() => manageItem(i.item, "ADD")}
															>
																<PlusOutlined className="flex border-red-500 border rounded-full p-2" />
															</button>
														</div>
														<button
															type="button"
															className="flex items-center "
															onClick={() => manageItem(i.item, "REMOVE")}
														>
															<Trash
																className=" leading-none"
																style={{ stroke: "#EA2F3E" }}
															/>
														</button>
													</div>
												</div>
											))}
										</div>
									</Col>
								)}
							</Row>
						</Form>
					</div>
					<div className="flex justify-center">
						<button
							className="btn btn--primary w-2/6 flex items-center justify-center"
							onClick={() => form.submit()}
							disabled={loading}
						>
							Submit
							{loading && <LoadingOutlined className="ml-2" />}
						</button>
					</div>
				</div>
			) : (
				<ProductList
					toggle={toggle}
					items={items}
					addItems={(items) => setItems(items)}
				/>
			)}
		</div>
	);
};

export default CreateOrder;
