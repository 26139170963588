import React, { useState, useEffect } from "react";
import { PlusOutlined } from "@ant-design/icons";
import product from "src/services/user/products";
import { Product } from "src/interface/product";
import ProductsList from "./ProductsList";
import CreateProduct from "./CreateProduct";
import CreateCategory from "./CreateCategory";
import { TabHead, TabPane } from "src/components/common/Tab";
import { Tab } from "@headlessui/react";
import { Category } from "src/interface/user/category";
import CategoryList from "./CategoryList";
import categoryService from "src/services/user/categories";

import { WithPermission } from "src/utils/utils";

const Locations = () => {
	const [products, setProducts] = useState<Product[]>([]);
	const [selected, setSelected] = useState<Product>();
	const [createModal, setCreateModal] = useState<boolean>(false);
	const [categoryModel, setCategoryModel] = useState<boolean>(false);
	const [selectedCategory, setSelectedCategory] = useState<Category>();
	const [active, setActive] = useState<number>(0);
	const [categories, setCategories] = useState<Category[]>([]);

	useEffect(() => {
		getProducts();
		getCategories();
	}, []);

	// Api Functions
	const getCategories = async () => {
		const res = await categoryService.List();
		if (res?.status && res?.data) {
			setCategories(res.data);
		}
	};

	const getProducts = async () => {
		const res = await product.list();
		if (res?.status && res?.data) {
			setProducts(res.data);
		}
	};

	//Toggle Functions
	const toggleCreateModal = () => {
		setCreateModal(!createModal);
	};
	const toggleCategoryModal = () => {
		setCategoryModel(!categoryModel);
	};

	// Selecting record for update
	const selectProduct = (prod: Product | undefined) => {
		toggleCreateModal();
		setSelected(prod);
	};

	const selectCategory = (cat: Category | undefined) => {
		toggleCategoryModal();
		setSelectedCategory(cat);
	};

	return (
		<div className="p-4 rounded-md bg--secondary">
			<Tab.Group onChange={(value: number) => setActive(value)}>
				<div className="flex justify-between items-center">
					<Tab.List
						className="flex p-1 space-x-1 bg--primary rounded-xl justify-start"
						style={{ width: "fit-content" }}
					>
						<WithPermission permission="product.list">
							<TabHead>Products</TabHead>
						</WithPermission>
						<WithPermission permission="category.list">
							<TabHead>Categories</TabHead>
						</WithPermission>
					</Tab.List>
					<div className="flex justify-end mb-4 space-x-3">
						{active === 1 && (
							<WithPermission permission="category.create">
								<button
									className="py-2 px-6 text-sm leading-5 font-medium text-white rounded-md  flex items-center btn btn--border "
									onClick={toggleCategoryModal}
								>
									<PlusOutlined className="mr-1 " /> Create Category
								</button>
							</WithPermission>
						)}
						{active === 0 && (
							<WithPermission permission="product.create">
								<button
									className="py-2 px-6 text-sm leading-5 font-medium text-white rounded-md  flex items-center btn btn--border "
									onClick={toggleCreateModal}
								>
									<PlusOutlined className="mr-1 " /> Create Product
								</button>
							</WithPermission>
						)}
					</div>
				</div>
				<Tab.Panels>
					<WithPermission permission="product.list">
						<TabPane>
							<ProductsList products={products} onRow={selectProduct} />
						</TabPane>
					</WithPermission>
					<WithPermission permission="category.list">
						<TabPane>
							<CategoryList categories={categories} onRow={selectCategory} />
						</TabPane>
					</WithPermission>
				</Tab.Panels>
			</Tab.Group>

			<WithPermission permission="product.create">
				<CreateProduct
					modal={createModal}
					callBack={getProducts}
					selected={selected}
					selectProduct={selectProduct}
				/>
			</WithPermission>

			<WithPermission permission="category.create">
				<CreateCategory
					modal={categoryModel}
					callBack={getCategories}
					selected={selectedCategory}
					selectCategory={selectCategory}
				/>
			</WithPermission>
		</div>
	);
};

export default Locations;
