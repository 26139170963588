import React from "react";
import { Tab } from "@headlessui/react";

function classNames(...classes: string[]) {
	return classes.filter(Boolean).join(" ");
}

export const TabHead = ({ children, key }: any) => (
	<Tab
		key={key}
		className={({ selected }) =>
			classNames(
				" py-2 px-10 text-sm leading-5 font-medium text-white rounded-lg transition-colors",
				"focus:outline-none  ring-offset-2 ring-offset-blue-400 ring-white ring-opacity-60",
				selected
					? " shadow btn--border"
					: "text-blue-100 text-opacity-60 hover:bg-gray-200 hover:bg-opacity-5 hover:text-gray-500"
			)
		}
	>
		{children}
	</Tab>
);

export const TabPane = ({ children }: any) => (
	<Tab.Panel
		className={classNames(
			" rounded-xl  mt-3",
			"focus:outline-none ring-offset-2 ring-offset-blue-400 ring-white ring-opacity-60"
		)}
	>
		{children}
	</Tab.Panel>
);
