import React from "react";
import Reports from "src/components/user/report";

const Report = () => {
	return (
		<>
			<Reports />
		</>
	);
};

export default Report;
