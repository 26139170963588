import React from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment, ReactChild } from "react";
import { CloseOutlined } from "@ant-design/icons";

interface IProps {
	open: boolean;
	children: ReactChild;
	title?: string | JSX.Element;
	okText?: string;
	cancelText?: string;
	onCancel: (e: any) => void;
	onOk?: (e: any) => void;
	footer?: JSX.Element;
	extras?: JSX.Element;
	size?: "lg" | "xl" | number;
	disabled?: boolean;
	className?: string;
	bgColor?: string;
}

const Modal = ({
	open,
	onCancel,
	children,
	title,
	okText,
	cancelText,
	onOk,
	footer,
	size,
	extras,
	disabled,
	bgColor,
}: IProps) => {
	return (
		<>
			<Transition show={open} as={Fragment}>
				<Dialog
					as="div"
					className="fixed inset-0 z-10 overflow-y-auto"
					initialFocus={undefined}
					static
					open={open}
					onClose={onCancel}
				>
					<div className="min-h-screen px-4 text-center overflow-auto">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="ease-in duration-100"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<Dialog.Overlay
								style={{ backdropFilter: "blur(2px)" }}
								className="fixed inset-0 h-[72px]   bg-opacity-20 bg-black  backdrop-filter   "
								onClick={() => {
									// dont do anything
								}}
							/>
						</Transition.Child>

						{/* This element is to trick the browser into centering the modal contents. */}
						<span
							className="inline-block h-screen align-middle"
							aria-hidden="true"
						>
							&#8203;
						</span>
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 scale-95"
							enterTo="opacity-100 scale-100"
							leave="ease-in duration-100"
							leaveFrom="opacity-100 scale-100"
							leaveTo="opacity-0 scale-95"
						>
							<div
								style={{
									background: bgColor ? bgColor : "#303030",
									maxWidth:
										size === "lg"
											? "32rem"
											: size === "xl"
											? "36rem"
											: typeof size === "number"
											? size + "rem"
											: "32rem",
								}}
								className={`inline-block w-full p-8 my-6 text-left align-middle transition-all transform bg-white shadow-xl rounded-xl `}
							>
								{typeof title === "string" ? (
									<Dialog.Title
										as="h3"
										className="text-lg font-medium leading-6 text-white mb-6"
									>
										<div className="flex justify-between items-start">
											<div>{title}</div>

											{!extras ? (
												<CloseOutlined
													className="cursor-pointer"
													onClick={onCancel}
												/>
											) : (
												<div>{extras}</div>
											)}
										</div>
									</Dialog.Title>
								) : (
									<Dialog.Title as="div" className="text-white"></Dialog.Title>
								)}
								{children}

								{!footer ? (
									<div className="mt-4 flex justify-end">
										{cancelText && (
											<button
												type="button"
												className={`modal-btn-red ${
													disabled
														? "cursor-not-allowed text-gray-500"
														: "text-red-900"
												}`}
												onClick={onCancel}
												disabled={disabled}
											>
												{cancelText}
											</button>
										)}
										{okText && (
											<button
												type="button"
												className={`modal-btn-indigo  ${
													disabled
														? "cursor-not-allowed text-gray-500"
														: "text-indigo-900"
												}`}
												onClick={onOk}
												disabled={disabled}
											>
												{okText}
											</button>
										)}
									</div>
								) : (
									<div className="mt-4 ">{footer}</div>
								)}
							</div>
						</Transition.Child>
					</div>
				</Dialog>
			</Transition>
		</>
	);
};

export default Modal;
