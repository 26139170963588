import { useEffect, useState } from "react";
import DeviceService from "../services/user/devices";
import { robotState } from "../config/constants";
import moment from "moment";

import { TelemetryDataType } from "../interface/user/telemetry";
import { Pose } from "../interface/device";

type Telemetry = {
	battery: number | undefined;
	state: number | undefined;
	pose: Pose | undefined;
};

export const useTelemetry = (id: string) => {
	const [telemetryData, setTelemetryData] = useState<Telemetry>({
		battery: undefined,
		state: undefined,
		pose: undefined,
	});

	useEffect(() => {
		setTelemetryData({ battery: undefined, state: undefined, pose: undefined });
		const res = DeviceService.GetLiveTelemetry(id);
		if (res) {
			let time: string;
			res.onmessage = (event) => {
				if (event.data) {
					time = moment().format("HH:mm:ss");
					const parsed: TelemetryDataType = JSON.parse(event.data);
					setTelemetryData((prev) => {
						return {
							battery:
								parsed.data.topics.agent_battery?.[0].value ?? prev?.battery,
							state: parsed.data.topics.agent_state?.[0].state ?? prev?.state,
							pose: parsed.data.topics.agent_pose?.[0] ?? prev?.pose,
						};
					});
				}

				const checkTime = setInterval(() => {
					const secondtime = moment().format("HH:mm:ss");
					const difference = moment(secondtime, "HH:mm:ss").diff(
						moment(time, "HH:mm:ss"),
						"second"
					);

					if (difference > 2 || difference === undefined) {
						setTelemetryData({
							battery: undefined,
							state: undefined,
							pose: undefined,
						});
						clearInterval(checkTime);
					}
				}, 5000);
			};

			res.onerror = () => {
				res && res.close();
			};
		}

		return () => {
			res && res.close();
		};
	}, [id]);

	return {
		battery: telemetryData.battery,
		state: robotState[telemetryData.state ? telemetryData.state : 13],
		pose: telemetryData.pose,
	};
};
