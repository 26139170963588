import { Resp } from "src/interface/common";
import { Product, ProductForm } from "src/interface/product";
import request from "../../utils/request";

const { REACT_APP_API_HOST: HOST } = process.env;

const list = (): Promise<Resp<Product[]>> => {
	return request(`${HOST}/api/v1/products`, {
		method: "GET",
		headers: {
			Authorization: `Bearer ${localStorage.getItem("access_token")}`,
		},
	});
};

const Detail = (id: string): Promise<Resp<Product>> => {
	return request(`${HOST}/api/v1/products/${id}`, {
		method: "GET",
		headers: {
			Authorization: `Bearer ${localStorage.getItem("access_token")}`,
		},
	});
};

const Create = (data: ProductForm.Create): Promise<Resp<Product>> => {
	return request(`${HOST}/api/v1/products`, {
		method: "POST",
		headers: {
			Authorization: `Bearer ${localStorage.getItem("access_token")}`,
		},
		data,
	});
};

const Update = (
	id: string,
	data: ProductForm.Create
): Promise<Resp<Product>> => {
	return request(`${HOST}/api/v1/products/${id}`, {
		method: "PATCH",
		headers: {
			Authorization: `Bearer ${localStorage.getItem("access_token")}`,
		},
		data,
	});
};

const Delete = (id: string): Promise<Resp<Product>> => {
	return request(`${HOST}/api/v1/products/${id}`, {
		method: "DELETE",
		headers: {
			Authorization: `Bearer ${localStorage.getItem("access_token")}`,
		},
	});
};

export default { list, Detail, Create, Update, Delete };
