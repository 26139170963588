import React from "react";
import ReactDOM from "react-dom";

import "../styles/index.css";
import "antd/dist/antd.css";

import App from "./App";
import { BrowserRouter } from "react-router-dom";
import Auth0ProviderWithHistory from "src/utils/auth0-provider-with-history";

ReactDOM.render(
	<React.StrictMode>
		<BrowserRouter>
			<Auth0ProviderWithHistory>
				<App />
			</Auth0ProviderWithHistory>
		</BrowserRouter>
	</React.StrictMode>,
	document.getElementById("root")
);
