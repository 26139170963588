import { Resp } from "src/interface/common";
import { RoomType, RoomTypeForm } from "src/interface/user/room";
import request from "src/utils/request";

const { REACT_APP_API_HOST: HOST } = process.env;

const List = (): Promise<Resp<RoomType[]>> => {
	return request(`${HOST}/api/v1/room_types`, {
		method: "GET",
		headers: {
			Authorization: `Bearer ${localStorage.getItem("access_token")}`,
		},
	});
};

const Create = (data: RoomTypeForm.Create): Promise<Resp<RoomType>> => {
	return request(`${HOST}/api/v1/room_types`, {
		method: "POST",
		data,
		headers: {
			Authorization: `Bearer ${localStorage.getItem("access_token")}`,
		},
	});
};

const Update = (
	id: string,
	data: RoomTypeForm.Create
): Promise<Resp<RoomType>> => {
	return request(`${HOST}/api/v1/room_types/${id}`, {
		method: "PATCH",
		data,
		headers: {
			Authorization: `Bearer ${localStorage.getItem("access_token")}`,
		},
	});
};

export default { List, Create, Update };
