import React, { useEffect, useState } from "react";

import users from "src/services/user/user";
import { User, UserUpdate } from "src/interface/user/user";
import { Col, Row, Form, Input, message } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { EditFilled } from "@ant-design/icons";

import ProfileIcon from "src/assets/Profile.svg";
import { useAuth0 } from "@auth0/auth0-react";

// import UpdateProfile from "./UpdateProfile";

const Profile = () => {
	const [form] = Form.useForm();
	// const [user, setUser] = useState<User>();
	const [isEdit, setIsEdit] = useState<boolean>(false);
	const { user, getIdTokenClaims } = useAuth0();

	useEffect(() => {
		form.setFieldsValue({
			first_name: user?.nickname,
			email: user?.email,
		});
	}, [user]);

	const fetchUser = async () => {
		const res = await users.Profile();
		if (res?.status && res.data) {
			// setUser(res.data);
		}
	};

	const handleSubmit = async (data: UserUpdate) => {
		const res = await users.Update(data);
		if (res?.status) {
			message.success("Profile updated successfully!");
			setIsEdit(false);
		}
	};

	return (
		<div className=" w-full h-full ">
			{user && (
				<>
					<div className="max-w-6xl mx-auto space-y-10">
						<div className="flex flex-col items-center relative">
							<h1 className="text-2xl font-bold text-white mb-10">
								My Profile
							</h1>
							{/* {!isEdit && (
								<button
									className=" text-xs  btn btn--border-transparent py-2 px-4 flex items-center absolute right-0 "
									onClick={() => setIsEdit(true)}
								>
									<EditFilled className="mr-1" />
									Edit
								</button>
							)} */}
							<img src={ProfileIcon} alt="" />
						</div>
						<Form form={form} onFinish={handleSubmit} id="update_profile_form">
							<Row gutter={[20, 7]}>
								<Col span={12}>
									<Form.Item
										name="first_name"
										rules={[
											{
												required: true,
												message: "First name is required!",
											},
										]}
									>
										<Input
											readOnly={!isEdit}
											autoComplete="off"
											type="text"
											placeholder="First Name"
											className="input--primary p-2 border-0 text-white"
										/>
									</Form.Item>
								</Col>
								{/* <Col span={12}>
									<Form.Item name="last_name">
										<Input
											readOnly={!isEdit}
											autoComplete="off"
											type="text"
											placeholder="Last Name"
											className="input--primary p-2 border-0 text-white"
										/>
									</Form.Item>
								</Col> */}

								<Col span={12}>
									<Form.Item name="email">
										<Input
											readOnly={!isEdit}
											autoComplete="off"
											type="email"
											placeholder="Email"
											className="input--primary p-2 border-0 text-white"
										/>
									</Form.Item>
								</Col>
								{/* <Col span={12}>
									<Form.Item
										name="mobile"
										rules={[
											{
												required: true,
												message: "Mobile number is required!",
											},
										]}
									>
										<Input
											readOnly={!isEdit}
											autoComplete="off"
											type="number"
											placeholder="Mobile Number"
											className="input--primary p-2 border-0 text-white number"
										/>
									</Form.Item>
								</Col>
								<Col span={24}>
									<Form.Item name="about">
										<TextArea
											readOnly={!isEdit}
											autoComplete="off"
											placeholder="About"
											className="input--primary p-2 border-0 text-white"
										/>
									</Form.Item>
								</Col> */}
							</Row>
						</Form>
						{isEdit && (
							<div className="flex justify-evenly">
								<button
									className="btn btn--border-transparent px-10 py-2"
									onClick={() => setIsEdit(false)}
								>
									Cancel
								</button>
								<button
									className="btn btn--primary px-10 py-2"
									form="update_profile_form"
									type="submit"
								>
									Update
								</button>
							</div>
						)}
					</div>
				</>
			)}
		</div>
	);
};

export default Profile;
