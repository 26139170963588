import React from "react";
import { Progress } from "antd";
import { StarFilled } from "@ant-design/icons";

interface props {
	data: {
		ratings: number[];
		total: number;
	};
	flex?: boolean;
}

const Ratings = ({ data, flex }: props) => {
	const name = [5, 4, 3, 2, 1];
	return (
		<div className="col-span-5">
			<h1
				className={`${flex ? "text-white" : "text-black"} text-lg font-medium`}
			>
				Reviews
			</h1>
			<div className="p-4">
				{data.ratings.map((i, index) => (
					<div className="flex items-center h-10 space-x-3" key={index}>
						<div
							className="text-right font-semibold w-3"
							style={{ fontSize: "18px" }}
						>
							{name[index]}
						</div>
						<StarFilled style={{ fontSize: "20px" }} />
						<Progress
							percent={Math.round((i / data.total) * 100)}
							strokeWidth={10}
							trailColor="gray"
							strokeColor={{
								"0%": "#d63254",
								"100%": "#f64d37",
							}}
							style={{ WebkitTextFillColor: flex ? "white" : "black" }}
						/>
					</div>
				))}
			</div>
		</div>
	);
};

export default Ratings;
