import React, { useState, useEffect } from "react";
import { Location } from "src/interface/admin/location";
import room from "src/services/user/rooms";
import CreateRoom from "./CreateRoom";
import CreateRoomType from "./CreateRoomType";
import RoomsList from "./RoomsList";
import UpdateRoom from "./UpdateRoom";
import roomType from "src/services/user/roomtypes";

import { PlusOutlined } from "@ant-design/icons";
import { Room } from "src/interface/order";
import { RoomType } from "src/interface/user/room";
import { Tab } from "@headlessui/react";
import { TabHead, TabPane } from "src/components/common/Tab";
import RoomtypesList from "./RoomtypesList";
import { WithPermission } from "src/utils/utils";

const Locations = () => {
	const [rooms, setRooms] = useState<Room[]>([]);
	const [createModal, setCreateModal] = useState<boolean>(false);
	const [roomTypeModal, setRoomTypeModal] = useState<boolean>(false);
	const [roomTypes, setRoomTypes] = useState<RoomType[]>([]);
	const [active, setActive] = useState<number>(0);

	const [selectedRoom, setSelectedRoom] = useState<Room>();
	const [selectedRoomtype, setSelectedRoomtype] = useState<RoomType>();

	useEffect(() => {
		getRooms();
		getRoomTypes();
	}, []);

	const toggleCreateModal = () => {
		setCreateModal(!createModal);
	};
	const toggleRoomTypeModal = () => {
		setRoomTypeModal(!roomTypeModal);
	};

	const getRooms = async () => {
		const res = await room.List();
		if (res?.status && res?.data) {
			setRooms(res.data);
		}
	};

	const getRoomTypes = async () => {
		const res = await roomType.List();
		if (res?.status && res?.data) {
			setRoomTypes(res.data);
		}
	};

	const selectRoom = (room: Room | undefined) => {
		toggleCreateModal();
		setSelectedRoom(room);
	};

	const selectRoomtype = (roomtype: RoomType | undefined) => {
		toggleRoomTypeModal();
		setSelectedRoomtype(roomtype);
	};

	return (
		<div className="p-4 rounded-md bg--secondary">
			<Tab.Group onChange={(value: number) => setActive(value)}>
				<div className="flex justify-between items-center">
					<Tab.List
						className="flex p-1 space-x-1 bg--primary rounded-xl justify-start"
						style={{ width: "fit-content" }}
					>
						<WithPermission permission="room.list">
							<TabHead>Rooms</TabHead>
						</WithPermission>
						<WithPermission permission="roomtype.list">
							<TabHead>Room Types</TabHead>
						</WithPermission>
					</Tab.List>
					<div className="flex justify-end mb-4 space-x-3">
						{active === 1 && (
							<WithPermission permission="room.create">
								<button
									className="py-2 px-6 text-sm leading-5 font-medium text-white rounded-md  flex items-center btn btn--border "
									onClick={toggleRoomTypeModal}
								>
									<PlusOutlined className="mr-1 " /> Create Room Type
								</button>
							</WithPermission>
						)}
						{active === 0 && (
							<WithPermission permission="roomtype.create">
								<button
									className="py-2 px-6 text-sm leading-5 font-medium text-white rounded-md  flex items-center btn btn--border "
									onClick={toggleCreateModal}
								>
									<PlusOutlined className="mr-1 " /> Create Room
								</button>
							</WithPermission>
						)}
					</div>
				</div>
				<Tab.Panels>
					<WithPermission permission="room.list">
						<TabPane>
							<RoomsList rooms={rooms} onRow={selectRoom} />
						</TabPane>
					</WithPermission>
					<WithPermission permission="roomtype.list">
						<TabPane>
							<RoomtypesList roomtypes={roomTypes} onRow={selectRoomtype} />
						</TabPane>
					</WithPermission>
				</Tab.Panels>
			</Tab.Group>

			<CreateRoom
				modal={createModal}
				toggleModal={toggleCreateModal}
				callBack={getRooms}
				selected={selectedRoom}
				selectDevice={selectRoom}
			/>
			<CreateRoomType
				modal={roomTypeModal}
				toggleModal={toggleRoomTypeModal}
				callBack={getRoomTypes}
				selected={selectedRoomtype}
				selectRoomtype={selectRoomtype}
			/>
		</div>
	);
};

export default Locations;
