import React from "react";
import { Table } from "antd";
import { LocationProps, Location } from "../../../interface/admin/location";

const LocationsList = ({ locations, onRow }: LocationProps.List) => {
	const column = [
		{
			title: "Name",
			key: "name",
			align: "left" as const,

			render: ({ name }: Location) => (
				<p className="m-0 font-semibold">{name}</p>
			),
		},
		{
			title: "Description",
			key: "description",
			align: "center" as const,

			render: ({ description }: Location) => (
				<p className="m-0 font-medium">{description}</p>
			),
		},
		{
			title: "Latitude",
			key: "latitude",
			align: "center" as const,

			render: ({ latitude }: Location) => (
				<p className="m-0 font-medium">{latitude}</p>
			),
		},
		{
			title: "Longitude",
			key: "longitude",
			align: "center" as const,

			render: ({ longitude }: Location) => (
				<p className="m-0 font-medium">{longitude}</p>
			),
		},
	];
	return (
		<div>
			<Table
				columns={column}
				dataSource={locations}
				rowClassName="cursor-pointer header-center"
				onRow={(row) => {
					return {
						onClick: () => onRow(row),
					};
				}}
			/>
		</div>
	);
};

LocationsList.displayName = "LocationsList";

export default LocationsList;
