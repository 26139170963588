import React, { useState } from "react";
import { useEffect } from "react";
import { Item, Product, ProductListProps } from "src/interface/product";
import {
	PlusOutlined,
	MinusOutlined,
	ArrowRightOutlined,
} from "@ant-design/icons";

import product from "../../../services/user/products";
import Arrow from "../../../assets/Arrow.svg";
import { List, message } from "antd";

const ProductList = (props: ProductListProps) => {
	const [products, setProducts] = useState<Product[]>([]);
	const [items, setItems] = useState<Item[]>(props.items);

	useEffect(() => {
		getProducts();
	}, []);

	const getProducts = async () => {
		const res = await product.list();
		if (res?.status && res?.data) {
			setProducts(res.data);
		}
	};

	const manageItem = (product: Product, process: "ADD" | "SUB" | "REMOVE") => {
		const prods: Item[] = JSON.parse(JSON.stringify(items));
		const index = prods.findIndex((i) => i.item.id === product.id);
		if (process === "ADD") {
			if (index > -1) {
				if (prods[index].quantity >= product.quantity) {
					return message.error(
						`The product has only ${product.quantity} numbers in stock!`
					);
				}
				prods[index].quantity += 1;
			} else {
				if (product.quantity < 1) {
					return message.error(
						`The product has only ${product.quantity} numbers in stock!`
					);
				}
				prods.push({
					item: product,
					quantity: 1,
				});
			}
		} else if (process === "SUB") {
			if (index > -1) {
				prods[index].quantity -= 1;
				if (prods[index].quantity === 0) {
					prods.splice(index, 1);
				}
			}
		} else if (process === "REMOVE") {
			setItems(items.filter((i) => i.item.id !== product.id));
			return;
		}
		setItems(prods);
	};

	return (
		<div className="relative w-full">
			<div className="mt-8 mb-8 flex items-center ">
				<img
					src={Arrow}
					alt=""
					className="cursor-pointer"
					onClick={() => props.toggle()}
				/>
				<h1 className="font-bold text-xl text-white text-center flex-1">
					Select your items
				</h1>
			</div>
			<div className="pb-20">
				<List
					grid={{
						gutter: 26,
						xs: 1,
						sm: 2,
						md: 2,
						lg: 3,
						xl: 4,
						xxl: 4,
					}}
					dataSource={products}
					renderItem={(item) => (
						<List.Item>
							<div
								style={{ background: "#1E1E1E" }}
								className="p-6 rounded-card cursor-pointer relative overflow-hidden"
							>
								<div className="mb-12">
									<h1 className="font-semibold text-lg text-white">
										{item.name}
									</h1>
									<p className="line-clamp-1">{item.description}</p>
								</div>
								<div className="btn--primary  flex items-center space-x-8  px-6  absolute bottom-0 right-0 rounded-tl-2xl">
									<button className="p-3">
										<MinusOutlined
											className="flex"
											onClick={() => manageItem(item, "SUB")}
										/>
									</button>
									<span className="font-semibold">
										{items.find((i) => i.item.id === item.id)?.quantity ?? 0}
									</span>
									<button
										className="p-3"
										onClick={() => manageItem(item, "ADD")}
									>
										<PlusOutlined className="flex" />
									</button>
								</div>
							</div>
						</List.Item>
					)}
				/>
			</div>
			<div className="flex justify-center">
				<div className="btn--primary p-4 rounded fixed w-4/6 bottom-3 flex justify-between">
					<span className="font-semibold">
						{items.map((i) => `${i.item.name}-${i.quantity}`).join(", ")}
					</span>
					<button
						className="font-semibold flex items-center"
						onClick={() => {
							props.addItems(items);
							props.toggle();
						}}
					>
						Proceed <ArrowRightOutlined />
					</button>
				</div>
			</div>
		</div>
	);
};

export default ProductList;
