import React, { useState, useEffect, ReactNode } from "react";

import { Switch, Route, Redirect, useHistory } from "react-router-dom";
import { HomeFilled, CompassOutlined } from "@ant-design/icons";

import LoginPage from "../pages/Login";
import Home from "../pages/Dashboard";
import Sider from "../components/Sider";
import { AlertCircle, AlertTriangle } from "react-feather";

// import { isAuthenticated } from "../utils/auth";
import DeviceDetail from "../pages/DeviceDetail";
import CreateOrder from "../components/user/order/CreateOrder";
import Orders from "../pages/Orders";
import OrderDetail from "../components/user/order/OrderDetail";
import Locations from "src/pages/Locations";
import Rooms from "src/pages/Rooms";
import Products from "src/pages/Products";
import Profile from "src/components/Profile";
import { messaging } from "src/utils/firebase-message";
import { getToken, onMessage } from "firebase/messaging";
import User from "src/services/user/user";
import { notification } from "antd";
import { menu } from "src/components/common/Dropdown";
import { hasPermission } from "src/utils/utils";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import config from "src/config";
import Report from "../pages/Report";
import Guests from "src/pages/Guests";

export type MenuList = {
	key: string;
	route: string;
	component: any;
	icon?: ReactNode;
	permission: string;
};

export interface Notification {
	title: string;
	message: string;
	order_id: string;
	order_code: string;
	failure_reason?: string;
	type: "FAILED_ORDER" | "REPORT";
}

const menuList: MenuList[] = [
	{
		key: "Dashboard",
		route: "/dashboard",
		component: React.lazy(() => import("../pages/Dashboard")),
		icon: <HomeFilled />,
		permission: "dashboard",
	},
	{
		key: "Orders",
		route: "/orders",
		component: React.lazy(() => import("../pages/Orders")),
		icon: <HomeFilled />,
		permission: "order",
	},
	{
		key: "Locations",
		route: "/locations",
		component: React.lazy(() => import("../pages/Locations")),
		icon: <CompassOutlined />,
		permission: "location",
	},
	{
		key: "Rooms",
		route: "/rooms",
		component: React.lazy(() => import("../pages/Rooms")),
		icon: <CompassOutlined />,
		permission: "room",
	},
	{
		key: "Products",
		route: "/products",
		component: React.lazy(() => import("../pages/Products")),
		icon: <CompassOutlined />,
		permission: "product",
	},
	{
		key: "Guests",
		route: "/guests",
		component: React.lazy(() => import("../pages/Guests")),
		icon: <HomeFilled />,
		permission: "report",
	},
	{
		key: "Reports",
		route: "/report",
		component: React.lazy(() => import("../pages/Report")),
		icon: <HomeFilled />,
		permission: "report",
	},
];

const PrivateRoute = ({ component, hasPermission, token, ...rest }: any) => {
	const { isAuthenticated } = useAuth0();
	console.log("In Private Route", isAuthenticated);
	console.log("Component", component);
	// if (!Component) return null;
	const history = useHistory();
	if (!isAuthenticated) {
		window.location.pathname = "/";
	}
	return token ? (
		<Route
			{...rest}
			slideUp={20}
			component={withAuthenticationRequired(component)}
		/>
	) : (
		<></>
	);
};

function App() {
	const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
	const history = useHistory();
	const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
	const [fcmToken, setFcmToken] = useState<string | null>(null);
	const [notifications, setNotifications] = useState<menu[] | []>([]);
	const [token, setToken] = useState<string | null>(null);

	useEffect(() => {
		if (isAuthenticated) {
			getAccessToken();
		}
	}, [isAuthenticated, isLoading]);
	useEffect(() => {
		async function generateToken() {
			if (isAuthenticated) {
				Notification.requestPermission().then((permission) => {
					if (permission === "granted") {
						messaging;
						getToken(messaging, {
							vapidKey:
								"BJiSoahuVeGUfa6glGoRbbVIuKsVZF0rbMsEg77xEo7SHCF7Q071B3PyQPf2shCr62Q52TXcY90oupzuMaVR1ao",
						})
							.then(async (currentToken) => {
								if (currentToken) {
									// Send the token to your server and update the UI if necessary
									// ...
									await User.SubscribeFcm(currentToken);
									setFcmToken(currentToken);
								} else {
									// Show permission request UI
									console.log(
										"No registration token available. Request permission to generate one."
									);
									// ...
								}
							})
							.catch((err) => {
								console.log("An error occurred while retrieving token. ", err);
								// ...
							});
					}
				});
			}
		}
		console.log(isAuthenticated);
		generateToken();

		return cleanup;
	}, [isAuthenticated, isLoading]);

	const getAccessToken = async () => {
		const accessToken = await getAccessTokenSilently({
			audience: process.env.REACT_APP_AUTH0_HOTEL_ROBOT_AUDIENCE,
			scope: config.permissions.join(" "),
		});
		localStorage.setItem("access_token", accessToken);
		setToken(accessToken);
	};

	const cleanup = () => {
		if (fcmToken) {
			User.UnsubscribeFcm(fcmToken);
		}
	};

	const clearNotification = () => {
		setNotifications([]);
	};

	const openNotification = (data: Notification) => {
		// alert_audio.play();
		const title = data.type === "FAILED_ORDER" ? "Order Failed" : "User Report";
		const args = {
			message: title,
			description: data.message,
			duration: 0,
		};
		const new_notification = {
			title: title,
			extra: data.order_code,
			description: data.message,
			path: `/orders/${data.order_id}`,
			icon:
				data.type === "FAILED_ORDER" ? (
					<AlertCircle color="red" />
				) : (
					<AlertTriangle color="yellow" />
				),
		};
		setNotifications([...notifications, new_notification]);
		if (data.type === "FAILED_ORDER") {
			notification.error(args);
		} else {
			notification.warn(args);
		}
	};

	onMessage(messaging, (payload: any) => {
		navigator.serviceWorker
			.getRegistration("/firebase-cloud-messaging-push-scope")
			.then(() => {
				console.log(payload);
				openNotification(payload.data);
			});
	});
	console.log("Authentication status: ", isAuthenticated);
	return (
		<Sider
			menuList={menuList}
			needSider={false}
			notifications={notifications}
			clearNotification={clearNotification}
			token={token}
		>
			<React.Suspense fallback="Loading...">
				{isLoading ? (
					<div className="h-screen w-screen flex items-center justify-center">
						<Spin
							indicator={antIcon}
							size="large"
							style={{ color: "white", fontSize: "48px" }}
						/>
					</div>
				) : (
					<Switch>
						<Route
							exact
							path="/"
							render={() =>
								isAuthenticated && !isLoading ? (
									<Redirect to="/dashboard" />
								) : (
									<Redirect to="/login" />
								)
							}
						/>

						<Route exact path={"/login"} component={LoginPage} />
						{/* {token && (
							<> */}
						<PrivateRoute
							exact
							token={token}
							path="/dashboard"
							component={Home}
							hasPermission={true}
						/>
						<PrivateRoute
							exact
							token={token}
							path="/orders"
							component={Orders}
							// hasPermission={hasPermission("order")}
						/>
						<PrivateRoute
							exact
							token={token}
							path="/locations"
							component={Locations}
							hasPermission={hasPermission("location")}
						/>
						<PrivateRoute
							exact
							token={token}
							path="/rooms"
							component={Rooms}
							hasPermission={hasPermission("room")}
						/>
						<PrivateRoute
							exact
							token={token}
							path="/profile"
							component={Profile}
							hasPermission={true}
						/>
						<PrivateRoute
							exact
							token={token}
							path="/products"
							component={Products}
							hasPermission={hasPermission("product")}
						/>
						<PrivateRoute
							exact
							token={token}
							path="/orders/create"
							component={CreateOrder}
							hasPermission={hasPermission("order.create")}
						/>
						<PrivateRoute
							exact
							token={token}
							path="/orders/:id"
							component={OrderDetail}
							hasPermission={hasPermission("order")}
						/>
						<PrivateRoute
							exact
							token={token}
							path="/devices/:id"
							component={DeviceDetail}
							hasPermission={hasPermission("device")}
						/>
						<PrivateRoute
							exact
							token={token}
							path="/guests"
							component={Guests}
							hasPermission={hasPermission("device")}
						/>
						<PrivateRoute
							exact
							token={token}
							path="/report"
							component={Report}
							hasPermission={hasPermission("device")}
						/>
						{/* </>
						)} */}
					</Switch>
				)}
			</React.Suspense>
		</Sider>
	);
}

export default App;
