import React, { useEffect, useState } from "react";
import { DatePicker, message } from "antd";
import { Tab } from "@headlessui/react";
import { DownloadOutlined } from "@ant-design/icons";
import moment, { Moment } from "moment";
import download from "downloadjs";
import reportService from "src/services/user/report";
import orderService from "src/services/user/order";
import { PieGraph } from "src/interface/user/report";
import { AvgTime, GraphParams, OrderListParams } from "src/interface/order";
import { TabPane, TabHead } from "src/components/common/Tab";
import AmenitiesGraph from "./Amenities";
import MonthlyList from "./MonthlyList";
import PieChart from "./PieChart";
import Ratings from "./Ratings";
import Card from "./Card";
import html2pdf from "html2pdf.js";
import Modal from "src/components/common/Modal";
import "../../../styles/print.css";

const Report = () => {
	const [active, setActive] = useState<number>(0);
	const [avgTime, setAvgTime] = useState<AvgTime>();
	const [amenities, setAmenities] = useState<PieGraph>();
	const [date, setDate] = useState<GraphParams>({
		from: moment().startOf("month").toISOString(),
		to: moment().endOf("month").toISOString(),
	});
	const [params, setParams] = useState<OrderListParams>({
		robot: undefined,
		room_no: undefined,
		duration: undefined,
	});
	const ref = React.useRef<HTMLDivElement>(null);
	const [model, setModel] = useState<boolean>(false);

	useEffect(() => {
		getDetails(date);
		averageTime(date);
	}, []);

	const handleChange = async (value: Moment | null) => {
		if (value) {
			const from = value.startOf("month").toISOString();
			const to = value.endOf("month").toISOString();
			setDate({ from, to });
			averageTime({ from, to });
			getDetails({ from, to });
		}
	};
	const getDetails = async (date: GraphParams) => {
		if (date) {
			const res = await reportService.Detail(date);
			if (res.data && res.status) {
				setAmenities(res.data);
			}
		}
	};

	const downloadReport = async () => {
		const name = `${moment(date?.from).format("MMMM-YYYY")}-Report`;
		const res = await reportService.GetReport(params);
		if (res) {
			download(res, `${name}.xlsx`);
			message.success("Table Downloaded Successfully");
		}
	};

	const averageTime = async (data: GraphParams) => {
		const res = await orderService.avgTime(data);
		if (res.data && res.status) {
			setAvgTime(res.data);
		}
	};

	const toPdf = () => {
		const options = {
			pagebreak: { mode: ["avoid-all", "css", "legacy"] },
			filename: moment(date.from).format("MMMM-YYYY") + ".pdf",
			image: { type: "jpeg", quality: 1 },
			html2canvas: { dpi: 98, letterRendering: true, scale: 2 },
			jsPDF: {
				unit: "in",
				format: "a4",
				orientation: "portrait",
				putOnlyUsedFonts: true,
			},
		};

		html2pdf()
			.set(options)
			.from(ref.current)
			.save()
			.then(() => {
				message.success("PDF Downloaded Successfully");
				setModel(false);
			});
	};

	return (
		<>
			<div>
				<Tab.Group onChange={(value: number) => setActive(value)}>
					<div className="flex justify-between items-center p-4 rounded-lg bg--secondary ">
						<Tab.List
							className="flex bg--primary rounded-xl justify-start space-x-1 p-1"
							style={{ width: "fit-content" }}
						>
							<TabHead>Chart View</TabHead>
							<TabHead>Table View</TabHead>
						</Tab.List>
						<div className="flex justify-end space-x-3">
							<DatePicker
								picker="month"
								className="date rounded px-6 py-2"
								onChange={(value) => handleChange(value)}
								defaultValue={moment()}
								clearIcon
							/>

							{active === 0 && (
								<button
									className=" btn btn--border text-xs py-2 flex items-center"
									onClick={() => setModel(true)}
								>
									<DownloadOutlined className="mr-1" />
									Graph Report
								</button>
							)}
							{active === 1 && (
								<button
									className=" btn btn--border text-xs py-2 flex items-center"
									onClick={downloadReport}
								>
									<DownloadOutlined className="mr-1" />
									Table Report
								</button>
							)}
						</div>
					</div>
					<Tab.Panels>
						<TabPane>
							{amenities && (
								<div className="bg--black p-4 rounded-lg">
									<div className=" mb-5 grid grid-cols-12 gap-5">
										<div className="card-piechart p-8 col-span-5">
											<h1 className="text-white text-lg font-medium">
												Success Rate
											</h1>
											<PieChart
												style={{ height: "220px" }}
												data={amenities}
												radius={"85%"}
											/>
										</div>
										{avgTime && (
											<>
												<div className="card-piechart col-span-5 p-8">
													<Ratings data={avgTime.ratings} flex={true} />
												</div>
												<Card
													flex={true}
													data={avgTime}
													total={amenities.amenities.reduce(
														(a, b) => a + b.quantity,
														0
													)}
												/>
											</>
										)}
									</div>
									<div className="card-piechart p-8">
										<h1 className="text-white text-lg font-medium">
											Monthly Ordered Items
										</h1>
										<AmenitiesGraph data={amenities} />
									</div>
								</div>
							)}
						</TabPane>
						<TabPane>
							<div className="bg--secondary rounded-lg">
								<MonthlyList
									start={date?.from}
									end={date?.to}
									callBack={(params) => setParams(params)}
								/>
							</div>
						</TabPane>
					</Tab.Panels>
				</Tab.Group>
			</div>
			{amenities && (
				<Modal
					onCancel={() => setModel(false)}
					open={model}
					size={57}
					bgColor={"white"}
				>
					<div className="flex-col space-y-5 border border-opacity-20 border-black rounded">
						<div className="flex justify-between border border-opacity-20 border-black p-5 m-3 rounded">
							<h1 className="text-2xl text-black flex font-medium items-center">
								Preview
							</h1>
							<div className="flex justify-end space-x-3">
								<button
									className="py-1 px-3 border-black border rounded hover:bg-gray-300"
									onClick={() => setModel(false)}
								>
									Cancel
								</button>
								<button
									className="py-1 px-3 border-black border rounded hover:bg-gray-300"
									onClick={toPdf}
								>
									<DownloadOutlined className="mr-1" />
									Download
								</button>
							</div>
						</div>
						<div ref={ref}>
							{avgTime && (
								<div className="m-3">
									<div className="border border-opacity-20 border-black p-5 rounded ">
										<h1 className="text-2xl font-medium mb-2">Overview</h1>
										<div className="flex py-2 ">
											<div className="w-1/2">
												<h1 className="text-black text-lg font-medium">
													Success Rate
												</h1>
												<PieChart
													style={{ height: "200px", width: "380px" }}
													radius="70%"
													data={amenities}
												/>
											</div>
											<div className="w-1/2">
												<Ratings data={avgTime.ratings} flex={false} />
											</div>
										</div>
										<div>
											<Card
												flex={false}
												data={avgTime}
												total={amenities.amenities.reduce(
													(a, b) => a + b.quantity,
													0
												)}
											/>
										</div>
										<div className="py-4">
											<h1 className="text-black text-lg font-medium">
												Monthly Ordered Items
											</h1>
											<AmenitiesGraph data={amenities} width={true} />
										</div>
									</div>
								</div>
							)}
						</div>
					</div>
				</Modal>
			)}
		</>
	);
};
export default Report;
