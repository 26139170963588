import React, { useState } from "react";
import { Col, Form, Input, message, Row, InputNumber, Tooltip } from "antd";
import { DeleteFilled, ExclamationCircleOutlined } from "@ant-design/icons";

import Modal from "src/components/common/Modal";

import TextArea from "antd/lib/input/TextArea";
import MultiSelect from "src/components/common/Select";
import { useEffect } from "react";
import { ProductForm, ProductProps } from "src/interface/product";
import products from "src/services/user/products";
import category from "src/services/user/categories";
import { Category } from "src/interface/user/category";
import Chip from "src/components/common/Chip";

const CreateProduct = ({
	modal,
	callBack,
	selected,
	selectProduct,
}: ProductProps.Create) => {
	const [form] = Form.useForm();
	const [categories, setCategories] = useState<Category[]>([]);
	const [keywords, setKeywords] = useState<string[]>([]);
	const [keyword, setKeyword] = useState<string>();
	const [integrationKeys, setIntegrationKeys] = useState<string[]>([]);
	const [integrationKey, setIntegrationKey] = useState<string>();

	useEffect(() => {
		if (modal) {
			getCategories();
		}
	}, [modal]);

	useEffect(() => {
		if (selected) {
			form.setFieldsValue({
				name: selected.name,
				description: selected.description,
				price: selected.price,
				quantity: selected.quantity,
				reorder_level: selected.reorder_level,
				category: selected.category_id,
				tray_occupancy: selected.tray_occupancy,
				code: selected.code,
			});
			setKeywords(selected.keywords);
			setIntegrationKeys(selected.integration_keys);
		}
	}, [selected]);

	const getCategories = async () => {
		const res = await category.List();
		if (res?.status && res?.data) {
			setCategories(res.data);
		}
	};

	const handleSubmit = async (
		id: string | undefined,
		values: ProductForm.Create
	) => {
		if (selected && id) {
			console.log(values.tray_occupancy, typeof values.tray_occupancy);
			const res = await products.Update(id, {
				...values,
				keywords,
				integration_keys: integrationKeys,
				code: values.code || null,
			});

			if (res?.status && res?.data) {
				message.success("Product updated successfully!");
				reset();
				callBack();
			}
		} else {
			const res = await products.Create({
				...values,
				code: values.code || null,
				keywords,
				integration_keys: integrationKeys,
			});
			if (res.status && res?.data) {
				message.success("Product created successfully!");
				reset();
				callBack();
			}
		}
	};

	const reset = () => {
		form.resetFields();
		selectProduct(undefined);
		setKeyword(undefined);
		setIntegrationKey(undefined);
		setIntegrationKeys([]);
		setKeywords([]);
		callBack();
	};

	const deleteProd = async (id: string) => {
		const res = await products.Delete(id);
		console.log(res);
		if (res?.status) {
			message.success("Product deleted successfully");
			reset();
		}
	};

	return (
		<>
			<Modal
				title={selected ? "Update Product" : "Create Product"}
				onCancel={reset}
				size={50}
				open={modal}
				footer={
					<div className="flex justify-between">
						{selected ? (
							<button
								className="text-white flex items-center space-x-2 font-normal py-1 px-4 text-md cursor-pointer transition-shadow  rounded-md  hover:shadow-lg border border-gray-600"
								onClick={() => deleteProd(selected.id)}
							>
								<DeleteFilled className="text-red-500  " />
								<span>Delete</span>
							</button>
						) : (
							<div></div>
						)}
						<div className="space-x-3 ">
							<button className="btn btn--border py-1 px-6" onClick={reset}>
								Cancel
							</button>
							<button
								className="btn btn--primary py-1 px-6"
								form="create_product_form"
								type="submit"
							>
								{selected ? "Update" : "Create"}
							</button>
						</div>
					</div>
				}
			>
				<Form
					className="space-y-4"
					form={form}
					layout="vertical"
					name="create_product_form"
					onFinish={(value) => handleSubmit(selected?.id, value)}
				>
					<Row gutter={[20, 0]}>
						<Col span={18}>
							<Form.Item
								label="Name"
								name="name"
								rules={[{ required: true, message: "Name is required" }]}
							>
								<Input className="input--primary p-2 border-0 text-white"></Input>
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item
								label="Product Code"
								name={"code"}
								rules={[
									{
										min: 5,
									},
									{
										pattern: new RegExp(/^[0-9A-Z]+$/),
										message: "Code should be A-Z,0-9",
									},
								]}
							>
								<Input
									className="p-2 text-white border-0 input--primary"
									maxLength={5}
								></Input>
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={[20, 0]}>
						<Col span={16}>
							<Form.Item
								className="col-span-6"
								label="Category"
								name="category"
								rules={[
									{
										required: true,
										message: "Please select a category this product belong",
									},
								]}
							>
								<MultiSelect
									options={categories.map((c) => ({
										label: c.name,
										value: c.id,
									}))}
									onChange={(value) => form.setFieldsValue({ category: value })}
									selectedValue={form.getFieldValue("category")}
								></MultiSelect>
							</Form.Item>
						</Col>
						<Col span={8}>
							<Form.Item
								label={
									<span className="flex items-center">
										Tray Occupancy{" "}
										<Tooltip
											placement="topLeft"
											title={
												"Percentage of space occupied by this item when placed inside robot tray out of 100"
											}
										>
											<ExclamationCircleOutlined className="ml-1" />
										</Tooltip>
									</span>
								}
								name="tray_occupancy"
								rules={[
									{
										required: true,
										message: "Please enter tray occupancy",
									},
								]}
								className="mt"
							>
								<InputNumber className="input--primary p-1 w-full border-0 text-white"></InputNumber>
							</Form.Item>
						</Col>
					</Row>

					<Row gutter={[20, 0]}>
						<Col span={8}>
							<Form.Item
								label="Price"
								name="price"
								rules={[{ required: true, message: "Price is required" }]}
							>
								<InputNumber className="input--primary p-1 w-full border-0 text-white"></InputNumber>
							</Form.Item>
						</Col>
						<Col span={8}>
							<Form.Item
								label="Quantity"
								name="quantity"
								rules={[{ required: true, message: "Quantity is required" }]}
							>
								<InputNumber className="input--primary p-1 w-full border-0 text-white"></InputNumber>
							</Form.Item>
						</Col>
						<Col span={8}>
							<Form.Item label="Reorder Level" name="reorder_level">
								<InputNumber className="input--primary p-1 w-full border-0 text-white"></InputNumber>
							</Form.Item>
						</Col>
					</Row>

					<Row>
						<Col span={24}>
							<Form.Item label="Description" name="description">
								<TextArea className="input--primary p-2 border-0 text-white" />
							</Form.Item>
						</Col>
					</Row>

					<Row gutter={[12, 0]}>
						<Col span={12}>
							<Form.Item label="Keywords">
								<div className="flex items-center space-x-3">
									<Input
										onPressEnter={() => {
											keyword && setKeywords([...keywords, keyword]);
											setKeyword(undefined);
										}}
										className="input--primary p-2 border-0 text-white"
										value={keyword}
										onChange={(e) => setKeyword(e.target.value)}
									></Input>
									<button
										className="btn btn--border py-2 px-6"
										type="button"
										onClick={() => {
											keyword && setKeywords([...keywords, keyword]);
											setKeyword(undefined);
										}}
									>
										Add
									</button>
								</div>
								{keywords?.length > 0 && (
									<div className="m-2 space-x-1 flex flex-wrap">
										{keywords.map((kw, index) => (
											<Chip
												key={index}
												onDelete={(value) =>
													setKeywords(keywords.filter((k) => k !== value))
												}
											>
												{kw}
											</Chip>
										))}
									</div>
								)}
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label="Integration Product Keys">
								<div className="flex items-center space-x-3">
									<Input
										onPressEnter={() => {
											integrationKey &&
												setIntegrationKeys([
													...integrationKeys,
													integrationKey,
												]);
											setIntegrationKey(undefined);
										}}
										className="input--primary p-2 border-0 text-white"
										value={integrationKey}
										onChange={(e) => setIntegrationKey(e.target.value)}
									></Input>
									<button
										className="btn btn--border py-2 px-6"
										type="button"
										onClick={() => {
											integrationKey &&
												setIntegrationKeys([
													...integrationKeys,
													integrationKey,
												]);
											setIntegrationKey(undefined);
										}}
									>
										Add
									</button>
								</div>
								{integrationKeys?.length > 0 && (
									<div className="m-2 space-x-1 flex flex-wrap">
										{integrationKeys.map((kw, index) => (
											<Chip
												key={index}
												onDelete={(value) =>
													setIntegrationKeys(
														integrationKeys.filter((k) => k !== value)
													)
												}
											>
												{kw}
											</Chip>
										))}
									</div>
								)}
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</Modal>
		</>
	);
};

export default CreateProduct;
