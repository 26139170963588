import { Resp } from "src/interface/common";
import { Category, CategoryForm } from "src/interface/user/category";
import { RoomType, RoomTypeForm } from "src/interface/user/room";
import request from "src/utils/request";

const { REACT_APP_API_HOST: HOST } = process.env;

const List = (): Promise<Resp<Category[]>> => {
	return request(`${HOST}/api/v1/categories`, {
		method: "GET",
		headers: {
			Authorization: `Bearer ${localStorage.getItem("access_token")}`,
		},
	});
};

const Create = (data: CategoryForm.Create): Promise<Resp<Category>> => {
	return request(`${HOST}/api/v1/categories`, {
		method: "POST",
		headers: {
			Authorization: `Bearer ${localStorage.getItem("access_token")}`,
		},
		data,
	});
};

const Update = (
	id: string,
	data: CategoryForm.Create
): Promise<Resp<Category>> => {
	return request(`${HOST}/api/v1/categories/${id}`, {
		method: "PATCH",
		data,
		headers: {
			Authorization: `Bearer ${localStorage.getItem("access_token")}`,
		},
	});
};

const Delete = (id: string): Promise<Resp<Category>> => {
	return request(`${HOST}/api/v1/categories/${id}`, {
		method: "DELETE",
		headers: {
			Authorization: `Bearer ${localStorage.getItem("access_token")}`,
		},
	});
};

export default { List, Create, Update, Delete };
