import { useAuth0 } from "@auth0/auth0-react";
import { Table, TablePaginationConfig } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { useEffect } from "react";
import { MonthlyOrders } from "src/interface/user/report";
import report from "src/services/user/report";
import { getIcons } from "../order/OrderDetail";
import room from "src/services/user/rooms";
import { Device } from "src/interface/device";
import { OrderListParams, Room } from "src/interface/order";
import { FilterValue } from "antd/lib/table/interface";

interface Filters {
	start?: string;
	end?: string;
	callBack: (params: OrderListParams) => void;
}

const MonthlyList = ({ start, end, callBack }: Filters) => {
	const [loading, setLoading] = useState(false);
	const [orders, setOrders] = useState<MonthlyOrders[]>([]);
	const [devices, setDevices] = useState<Device[]>([]);
	const [rooms, setRooms] = useState<Room[]>([]);
	const [pagination, setPagination] = useState<TablePaginationConfig>({
		current: 1,
		pageSize: 10,
	});

	useEffect(() => {
		getOrders({ offset: pagination.current, limit: pagination.pageSize });
	}, [start]);

	useEffect(() => {
		getDevices();
		getRooms();
	}, []);

	const getDevices = async () => {
		const res = await report.deviceList();
		if (res?.status && res?.data) {
			setDevices(res.data);
		}
	};

	const getRooms = async () => {
		const res = await room.List();
		if (res?.status && res?.data) {
			setRooms(res.data);
		}
	};

	const getOrders = async ({
		offset,
		limit,
		robot,
		room_no,
		duration,
	}: OrderListParams) => {
		setLoading(true);
		const res = await report.MonthlyList({
			offset,
			limit,
			room_no,
			robot,
			duration,
			start,
			end,
		});
		if (res?.status && res?.data) {
			setOrders(res.data.orders);
			setPagination({
				current: offset,
				pageSize: pagination.pageSize,
				total: res.data.total,
			});
			setLoading(false);
		}
	};

	const column = [
		{
			title: "Order ID",
			key: "order_id",
			align: "center" as const,
			render: (record: MonthlyOrders) => {
				return record.order_id;
			},
		},
		{
			title: "Robot",
			key: "robot",
			align: "center" as const,
			filterSearch: true,
			filters: devices.map((i) => ({
				text: i.name,
				value: i.id,
			})),
			render: (record: MonthlyOrders) => {
				return record?.assignments[0]?.device?.name ?? "Unassigned";
			},
		},
		{
			title: "Room No",
			key: "room_no",
			align: "center" as const,
			filters: rooms.map((i) => ({
				text: i.name,
				value: i.id,
			})),
			filterSearch: true,
			render: (record: MonthlyOrders) => {
				return record.room.room_number;
			},
		},
		{
			title: "Items",
			key: "items",
			align: "center" as const,
			render: (record: MonthlyOrders) => {
				return record.cart.map((i, index) => (
					<span key={index}>
						{index > 0 ? ", " : ""}
						{i.product.name}
					</span>
				));
			},
		},
		{
			title: "Duration",
			key: "duration",
			align: "center" as const,
			filters: [
				{ text: "< 10 min", value: 600 },
				{ text: "< 20 min", value: 1200 },
				{ text: "< 30 min", value: 1800 },
				{ text: "< 45 min", value: 2700 },
			],
			filterMultiple: false,
			render: (record: MonthlyOrders) => {
				return (
					<div>
						{moment.utc(record.duration * 1000).format("mm:ss") + " " + "min"}
					</div>
				);
			},
		},
		{
			title: "Status",
			key: "status",
			align: "left" as const,

			render: (record: MonthlyOrders) => {
				return (
					<span className="capitalize flex items-center">
						<div
							className={`w-2 h-2 bg-${
								getIcons(record.order_status).color
							}-500 rounded-full mr-1`}
						></div>
						{record.order_status.toLowerCase().replace("_", " ")}
					</span>
				);
			},
		},
	];

	const onChange = (
		pagination: TablePaginationConfig,
		FilterValue: Record<string, FilterValue | null>
	) => {
		const data: OrderListParams = {
			offset: pagination.current,
			limit: pagination.pageSize,
			robot: FilterValue.robot,
			room_no: FilterValue.room_no,
			duration: FilterValue.duration,
		};
		getOrders(data);
		callBack(data);
	};

	return (
		<Table
			columns={column}
			dataSource={orders}
			pagination={pagination}
			onChange={(pagination, FilterValue, _sorting) =>
				onChange(pagination, FilterValue)
			}
			rowClassName="cursor-text"
			loading={loading}
		/>
	);
};
export default MonthlyList;
